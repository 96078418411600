<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <loginbanner></loginbanner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <div class="img-logo">
              <img
                src="../../../assets/img/logo.png"
                class="img-fluid"
                alt="Logo"
              />
              <div class="back-home">
                <router-link to="/">홈으로</router-link>
              </div>
            </div>
            <h1>비밀번호를 초기화 하시겠습니까?</h1>
            <div class="reset-password">
              <p>
                비밀번호를 잊어버리셨거나 변경하시고 싶으시면 '비밀번호
                초기화'를 클릭해주세요. 안전한 새로운 비밀번호를 설정할 수 있는
                링크가 이메일로 전송됩니다.
              </p>
            </div>
            <div>
              <div class="form-group">
                <label class="form-control-label">Email</label>
                <input
                  type="email"
                  class="form-control"
                  placeholder="Enter your email address"
                  v-model="email"
                />
                <span class="error-message">{{errorMessage}}</span>
              </div>
              <div class="d-grid">
                <button
                  class="btn btn-start"
                  type="submit"
                  @click="resetPassword(email)"
                >
                  비밀번호 초기화
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import {openModal, closeModal, container} from "jenesius-vue-modal";
import PaymentSuccessModal from '../instructor/paymentsuccessmodal.vue'
import UserService from "../../../services/user.service.js";
export default {
  components: {},
  data() {
    return {
      email: "",
      errorMessage: "",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    resetPassword(email) {
      this.errorMessage = null
      var pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
      if(email.match(pattern)) {
        this.$store.dispatch("auth/password_reset", email).then(()=> {
          this.email = ""
          alert('이메일을 통해 초기화된 비밀번호가 전송되었습니다.')
          this.$router.push('/login')
        },
        (error) => {
          alert('해당 이메일을 찾을 수 없습니다. 이메일 주소를 다시한번 확인해주세요.')
          this.errorMessage = '해당 이메일을 찾을 수 없습니다. 이메일 주소를 다시한번 확인해주세요.'
        })
      } else {
        this.errorMessage = '잘못된 이메일 주소입니다.'
        return false;
      }
    },
  },
};
</script>
