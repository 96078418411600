<template>
<!-- Deposit Modal -->
<div class="modal-styles" id="paymentsuccessmodal" tabindex="-1" aria-labelledby="addpaymentMethod" aria-hidden="true">
    <div class="modal-dialog small">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="addpaymentMethod">결제 성공</h5>
            </div>
            <div class="modal-body">
                <div class="addpaymethod-form add-course-info">
                    <form action="#">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mb-0">
                                    <label class="form-control-label">결제를 성공하였습니다.</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer me-auto">
                <router-link to="/" type="button" class="btn btn-modal-style btn-theme">홈으로 이동</router-link>
                <router-link to="/course-student" type="button" class="btn btn-modal-style btn-cancel">내 모델목록</router-link>
            </div>
        </div>
    </div>
</div>	   
<!-- Deposit Modal -->
</template>
<script>
// import {closeModal, container} from "jenesius-vue-modal";

// export default {
//   components: {WidgetContainerModal: container},
//   data() {
//   },
//   mounted() { 
//   },
//   destroyed() {
//     console.log('destroyed...');
//     closeModal()
//   }
// }
</script>