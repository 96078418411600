<template>
<!-- Login Banner -->
<div class="col-md-6 login-bg">
    <div class="owl-carousel login-slide owl-theme">
        <div class="welcome-login">
            <div class="login-banner">
                <img src="../assets/img/login-img.png" class="img-fluid" alt="Logo">
            </div>
            <Carousel>
                <Slide v-for="slide in 1" :key="slide">
                    <div class="mentor-course text-center">
                        <h2>환영합니다.</h2>
                        <p>언어모델 전문가로 거듭나기 위한 성장 로드맵</p>
                    </div>
                </Slide>
                <!-- <template #addons>
                    <Pagination />
                </template> -->
            </Carousel>
        </div>
    </div>
</div>
<!-- /Login Banner -->
</template>
<script>
    import { defineComponent } from 'vue'
    import { Carousel, Pagination, Slide } from 'vue3-carousel';
    
    import 'vue3-carousel/dist/carousel.css';
    
    export default defineComponent({
    name: 'Autoplay',
    components: {
    Carousel,
    Slide,
    Pagination,
    },
});
</script>