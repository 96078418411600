import BlogService from "../services/blog.service";

const initialState = {
  blogs: null,
  blog: null,
  comments: [],
}
  
export const blog = {
  namespaced: true,
  state: initialState,
  actions: {
    blog_list({ commit }, category) {
      return BlogService.getBlogList(category).then(
        (response) => {
          commit("blogListSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("blogListFailure");
          return Promise.reject(error);
        }
      );
    },
    blog_item({ commit }, id) {
      return BlogService.getBlog(id).then(
        (response) => {
          commit("blogSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("blogFailure");
          return Promise.reject(error);
        }
      );
    },
    comment({ commit }, payload) {
      return BlogService.comment(payload).then(
        (response) => {
          commit("commentSuccess", response.data);
          return Promise.resolve(response.data);
        }
      );
    },
  },
  mutations: {
    blogListSuccess(state, blogs) {
      state.blogs = blogs;
    },
    blogListFailure(state) {
      state.blogs = null;
    },
    blogSuccess(state, blog) {
      state.blog = blog
    },
    
    commentSuccess(state, data) {
      state.comments.push(data.content)
    },
  },
};
