<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <courseheader></courseheader>
    <!-- My Course -->
    <section class="course-content sub-page-content">
      <div class="container">
        <div class="student-widget">
          <div class="student-widget-group">
            <div class="row">
              <div class="col-lg-12" v-if="loaded">
                <!-- <studentfilter></studentfilter> -->
                <div class="row" v-if="myproducts && myproducts.length>0">
                  <div class="mobile-box col-xl-3 col-lg-4 col-md-6 d-flex" v-for="item, idx in myproducts" :key="item.id">
                    <div class="course-box d-flex" v-if="item">
                      <div class="product">
                        <div class="product-img">
                          <router-link :to="`product-details?id=${item.id}`">
                            <img
                              class="img-fluid"
                              alt=""
                              :src="`${serverURL}${item.image.file}`"
                            />
                          </router-link>
                        </div>
                        <div class="product-content">
                          <h3 class="title">
                            <router-link 
                              :to="`product-details?id=${item.id}`"
                              >{{item.title}}</router-link
                            >
                          </h3>
                          <p class="small mt-3">{{item.intro}}</p>
                          <!-- <div class="d-day">
                            <p><i style="color: #ff4668;">* 2023년 6월 10일 00시 1차 공개</i></p>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 d-flex" v-else>
                    <div class="course-box course-design d-flex">
                      <div class="">
                        <div class="product-content">
                          <div class="student-percent" style="color: #3a496c">
                            <p>모델을 아직 구입하지 않으셨습니다. 여러분의 성장을 돕는 모델을 찾아보세요.</p>
                          </div>
                          <div class="start-leason d-flex align-items-center">
                            <router-link
                              to="productlistindex"
                              class="btn btn-primary"
                              >모델 보기</router-link
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /My Course -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import Vue from "vue";
export default {
  components: {},
  data() {
    return {
      loaded: false
    };
  },
  computed: {
    myproducts() {
      return this.$store.state.product.myproducts;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if(this.loggedIn) {
      this.$store.dispatch('auth/check_auth').catch((error) => {
          this.$router.push("login");
        })
    }

    this.$store.dispatch("product/myproduct_list").then(()=>{
      this.loaded = true;
    });
  },
  methods: {},
};
</script>
