<template>
<!-- Footer Top -->
<div class="footer-top">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-12">
                <!-- Footer Widget -->
                <div class="footer-widget footer-about">
                    <div class="footer-logo">
                        <img src="../assets/img/logo.png" alt="logo">
                    </div>
                    <div class="footer-about-content">
                        <p><strong>(주)퀀텀아이</strong>는 언어모델 전문 기업입니다.</p>
                        <p class="small">(주)퀀텀아이 대표 이강훈</p>
                        <p class="small">사업자번호 329-88-01723</p>
                        <p class="small">서울시 마포구 토정로 18, 5층(합정동)</p>
                        <!-- <p class="small">통신판매업 제2021-서울중구-1837호</p> -->
                    </div>
                </div>
                <!-- /Footer Widget -->
            </div>
            
            <!-- <div class="col-lg-2 col-md-6">
                <div class="footer-widget footer-menu">
                    <h2 class="footer-title">For Instructor</h2>
                    <ul>
                        <li><router-link to="instructor-profile">Profile</router-link></li>
                        <li><router-link to="login">Login</router-link></li>
                        <li><router-link to="register">Register</router-link></li>
                        <li><router-link to="instructor-list">Instructor</router-link></li>
                        <li><router-link to="deposit-instructor-dashboard"> Dashboard</router-link></li>
                    </ul>
                </div>
            </div> -->
            
            <div class="col-md-3 col-6">
                <div class="footer-widget footer-menu">
                    <h2 class="footer-title">파트너 링크</h2>
                    <ul>
                        <li><a href="https://www.ai-lab.kr" target="_blank">(사)한국인공지능연구소</a></li>
                        <li><a href="https://ai-academy.ai" target="_blank">(주)한국인공지능아카데미</a></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-md-3 col-6">
            
                <!-- Footer Widget -->
                <div class="footer-widget footer-contact">
                    <h2 class="footer-title">연락처</h2>
                    <div class="footer-contact-info">
                        <!-- <p class="small"><router-link to="faq">자주 묻는 질문 FAQ</router-link></p> -->
                        <p class="small">개인정보보호책임자 문아라</p>
                        <p class="small">고객센터: 02-2039-0677</p>
                        <p class="small">Fax: 0303-3445-2260</p>
                        <p class="small">Email: ai@quantumai.kr</p>
                    </div>
                </div>
                <!-- /Footer Widget -->
                
            </div>
            
        </div>
    </div>
</div>
<!-- /Footer Top -->
</template>