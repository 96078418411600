

var SERVER_URL = process.env.SERVER_URL || 'http://localhost:8000';


const GOOGLE_LOGIN_CLIENT_ID = "709512186004-gjags3qmo02i7r45k4ctm6mb0nrr6bc5.apps.googleusercontent.com"
const GOOGLE_LOGIN_REDIRECT_URI = process.env.GOOGLE_LOGIN_REDIRECT_URI || "http://localhost:8080/login"

const GOOGLE_LOGIN_URL = `https://accounts.google.com/o/oauth2/auth?client_id=${GOOGLE_LOGIN_CLIENT_ID}&redirect_uri=${GOOGLE_LOGIN_REDIRECT_URI}&response_type=code&scope=email%20profile%20openid`


const KAKAO_LOGIN_CLIENT_ID = "41e3c64a473ca2b21ce324a21998de51"
const KAKAO_LOGIN_REDIRECT_URI = process.env.KAKAO_LOGIN_REDIRECT_URI || "http://localhost:8080/login"

const KAKAO_LOGIN_URL = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${KAKAO_LOGIN_CLIENT_ID}&redirect_uri=${KAKAO_LOGIN_REDIRECT_URI}`


export default (app) => {
    app.serverURL = SERVER_URL;
    app.config.globalProperties.serverURL = SERVER_URL;

    app.GOOGLE_LOGIN_URL = GOOGLE_LOGIN_URL;
    app.config.globalProperties.GOOGLE_LOGIN_URL = GOOGLE_LOGIN_URL;

    app.KAKAO_LOGIN_URL = KAKAO_LOGIN_URL;
    app.config.globalProperties.KAKAO_LOGIN_URL = KAKAO_LOGIN_URL;

    app.config.globalProperties.$filters = {
        comma(value) {
            return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
    }
}

