import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.BASE_URL || 'http://localhost:8000/api'


class CourseService {
  getPublicCourseList() {
    return axios.get(API_URL + '/course/org/quantumai/');
  };
  getPublicCourse(id) {
    return axios.get(API_URL + `/course/${id}/`);
  };
  getMyCourseList() {
    return axios.get(API_URL + `/course/mycourses/`, {
      headers: authHeader()
    });
  };
  joinCourseRequest(id) {
    return axios.patch(API_URL + `/course/${id}/join/`, {}, {
      headers: authHeader()
    });
  }
  getSubLesson(id) {
    return axios.get(API_URL + `/lesson/sublesson/${id}/`, {
      headers: authHeader()
    });
  };

  createHistory(payload) {
    return axios.post(API_URL + `/history/sublesson/${payload.course_id}/${payload.lesson_id}/${payload.sublesson_id}/`, {}, {
      headers: authHeader()
    });
  };
  getHistory() {
    return axios.get(API_URL + `/history/card/`, {
      headers: authHeader()
    });
  };
}

export default new CourseService();