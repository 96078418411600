<template>
  <!-- Feature Course -->
  <section class="section new-course">
    <div class="container main">
      <div class="section-header aos" data-aos="fade-up">
        <img
            class="img-fluid company-instruction"
            src="../../../assets/img/home/home_01.jpeg"
          />
      </div>
      <div class="section-header aos" data-aos="fade-up">
        <img
            class="img-fluid company-instruction"
            src="../../../assets/img/home/home_02.jpeg"
          />
      </div>
      <div class="section-header aos" data-aos="fade-up">
        <img
            class="img-fluid company-instruction"
            src="../../../assets/img/home/home_03.jpeg"
          />
      </div>
      <div class="section-header aos" data-aos="fade-up">
        <img
            class="img-fluid company-instruction"
            src="../../../assets/img/home/home_04.jpeg"
          />
      </div>
      <div class="section-header aos" data-aos="fade-up">
        <img
            class="img-fluid company-instruction"
            src="../../../assets/img/home/home_05.jpeg"
          />
      </div>
      <div class="section-header aos" data-aos="fade-up">
        <img
            class="img-fluid company-instruction"
            src="../../../assets/img/home/home_06.jpeg"
          />
      </div>
      
      <!-- <div class="section-text aos" data-aos="fade-up">
            <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean accumsan bibendum gravida maecenas augue elementum et neque. Suspendisse imperdiet.</p>
        </div> -->
      
    </div>
  </section>
  <!-- /Feature Course -->

  <!-- Master Skill -->
  <!-- <section class="section master-skill">
    <div class="container main">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head">
              <h2>언어모델 학습 로드맵</h2>
            </div>
          </div>
          <div class="section-text aos" data-aos="fade-up">
            <p>
              언어 모델링의 기초부터 전이학습, 최적화 및 효율화 전략, 그리고 실제 언어모델 기반 챗봇 프로젝트 구현까지, 언어모델 전문가가 되기 위한 종합적인 언어모델 학습 로드맵을 제공합니다.
            </p>
          </div>
        </div>
        <div class="col-lg-10 col-md-12">
          <div class="career-group aos" data-aos="fade-up">
            <div class="row">
              <div class="col-lg-12 col-md-12 d-flex">
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <div class="blur-box">
                      <div>
                        <img
                          src="../../../assets/img/roadmap.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- Master Skill -->
  <!-- <section class="section master-skill">
    <div class="container main">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head d-block d-sm-none">
              <h2>언어모델 전문가로<br /> 성장하세요.</h2>
            </div>
            <div class="section-sub-head d-none d-sm-block">
              <h2>언어모델 전문가로 성장하세요.</h2>
            </div>
          </div>
          <div class="section-text aos" data-aos="fade-up">
            <p>
              이 프로그램은 이론과 실제의 균형을 맞추어, 참가자들이 새로운
              지식을 실질적인 프로젝트에 적용할 수 있도록 돕습니다. 이를 통해
              참가자들은 실무에서 직면하는 도전과제를 해결하는 능력을 키울 수
              있습니다.
            </p>
          </div>
          <div class="career-group aos" data-aos="fade-up">
            <div class="row">
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-1.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>언어모델 설계 전문가</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-2.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>도메인 파인튜닝 전문가</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-3.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>경량화 및 최적화 전문가</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-4.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>언어모델 운영 전문가</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 d-flex align-items-end">
          <div class="career-img aos" data-aos="fade-up">
            <img
              src="../../../assets/img/join_02.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- /Master Skill -->

  <!-- B2B Contact -->
  <!-- <section class="section master-skill">
    <div class="container main">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head">
              <h2>기업교육 문의</h2>
            </div>
          </div>
          <div class="section-text aos" data-aos="fade-up">
            <p>
              언어모델 전문가 교육(온/오프라인)을 희망하는 기업의 경우 아래 이메일로 연락주세요. 
            </p>
            <p>Email: ai@quantumai.kr</p>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- /Master Skill -->
</template>
<script>
import AOS from "aos";

export default {
  components: {},
  data() {
    return {
      content: "",
      youtube_courses: [
        '8BWGMaeJ9dA',
      ]
    };
  },
  computed: {
    courses() {
      return this.$store.state.course.courses;
    },
    products() {
      return this.$store.state.product.products;
    },
  },
  methods: {
    getCourseDetailPath(id) {
       return 'course-details?id='+id
    },
    getProductDetailPath(id) {
       return 'product-details?id='+id
    },
    onReady() {
    },
    discounted_price(course) {
      let discount_price = 0
      discount_price = course.price * (1 - course.discount_rate/100) 
      discount_price = Math.round(discount_price / 1000) * 1000;
      return discount_price
    },
  },
  mounted() {
    this.$store.dispatch("course/course_list");
    this.$store.dispatch("product/product_list");

    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
  },
};
</script>
