import AuthService from "../services/auth.service";


const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    kakaoLogin({ commit }, code) {
      return AuthService.kakaoLogin(code).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    googleLogin({ commit }, code) {
      return AuthService.googleLogin(code).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    check_auth({ commit }) {
      return AuthService.checkAuth(user).then(
        (user) => {
          return Promise.resolve(user);
        },
        (error) => {
          commit("authFailure");
          return Promise.reject(error);
        }
      );
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (user) => {
          commit("registerSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("registerFailure");
          return Promise.reject(error);
        }
      );
    },
    password_reset({ commit }, email) {
      return AuthService.passwordReset(email);
    },
    password_change({ commit }, data) {
      return AuthService.passwordChange(data);
    },
    update_profile({ commit }, data) {
      return AuthService.updateProfile(data).then(
        (response) => {
          commit("updateProfileSuccess", response.data);
          return Promise.resolve(response.data);
        }
      );
    }
    
  },
  mutations: {

    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    authFailure(state) {
      state.status.loggedIn = false;
      state.user = null;

      localStorage.removeItem('user');
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    updateProfileSuccess(state, user) {
      state.user.user.name = user.name
      state.user.user.phone = user.phone
      localStorage.setItem('user', JSON.stringify(state.user));
    }
  },
};
