import AiagentService from "../services/aiagent.service";

const initialState = {
  messages: [],
}
  
export const aiagent = {
  namespaced: true,
  state: initialState,
  actions: {
    chatting({ commit }, payload) {
      return AiagentService.chatting(payload).then(
        (response) => {
          commit("chattingSuccess", response.data);
          return Promise.resolve(response.data);
        }
      );
    },
    message_list({ commit }, id ) {
      return AiagentService.message_list(id).then(
        (response) => {
          commit("messageListSuccess", response.data);
          return Promise.resolve(response.data);
        }
      )
    }
  },
  mutations: {
    chattingSuccess(state, data) {
      state.messages.push(data.content)
    },
    messageListSuccess(state, data) {
      state.messages = data
    }
  },
};
