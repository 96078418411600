<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>
    <!-- <coursedetails></coursedetails> -->
    <inner-page  
      v-if="course"
      :lesson_number="course.num_lessons" :time_number="course.duration_titme" class="mt-3"></inner-page>
    <!-- Course Content -->
    <section class="page-content course-sec">
      <div class="container" v-if="course">
        <div class="row">
          <div class="col-lg-8">
            <!-- Course Content -->
            <div class="card content-sec">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-6">
                    <h5 class="subs-title">강의 커리큘럼</h5>
                  </div>
                  <div class="col-sm-6 text-sm-end">
                    <!-- <h6>92 Lectures 10:56:11</h6> -->
                  </div>
                </div>
                <div
                  class="course-card"
                  v-for="(lesson, idx) in course.course_lessons"
                  :key="idx"
                >
                  <h6 class="cou-title">
                    <a
                      class="collapsed"
                      data-bs-toggle="collapse"
                      :href="`#collapse` + idx"
                      aria-expanded="false"
                      >{{ lesson.lesson.title }}</a
                    >
                  </h6>
                  <div
                    :id="`collapse` + idx"
                    class="card-collapse collapse"
                    :class="hasPreview(lesson)? 'show': ''"
                  >
                    <ul>
                      <li v-for="(sublesson, idx) in lesson.lesson.sublesson">
                        <p class="px-2">
                          <!-- <img
                            src="../../../../assets/img/icon/chapter.svg"
                            alt=""
                            class="me-2"
                          /> -->
                          - {{ sublesson.title }}
                        </p>
                        <div class="px-2" style="min-width: 80px;">
                          <a
                            href="javascript:;"
                            @click="getPreview(sublesson)"
                            v-if="!enrolled && sublesson.type"
                            class="preview"
                            >미리보기</a
                          >
                          <!-- <router-link :to="`course-lesson`+`?c=${course.id}&sl=${sublesson.id}`" v-if="!enrolled && sublesson.type">Preview</router-link> -->
                          <span>{{ sublesson.duration }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Course Content -->

            <!-- Overview -->
            <div class="card overview-sec">
              <div class="card-body">
                <h5 class="subs-title">강의소개</h5>
                <div class="video-container mb-4">
                  <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${course.video_url}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div v-dompurify-html="course.describe"></div>
              </div>
            </div>
            <!-- /Overview -->
            


            <!-- Instructor -->
            <div class="card instructor-sec">
              <div class="card-body">
                <h5 class="subs-title">강사 정보</h5>
                <div class="instructor-wrap">
                  <div class="about-instructor">
                    <div class="abt-instructor-img">
                      <router-link to="instructor-profile"
                        ><img
                          src="../../../../assets/img/user/user1.jpg"
                          alt="img"
                          class="img-fluid"
                      /></router-link>
                    </div>
                    <div class="instructor-detail">
                      <h5>이강훈</h5>
                      <p>(사)한국인공지능연구소 소장</p>
                    </div>
                  </div>
                </div>

                <p># 강사 이력:</p>
                <ul>
                  <li>- (사) 한국인공지능연구소 소장</li>
                  <li>- (주)한국인공지능아카데미 CTO</li>
                  <li>- (주) 퀀텀아이 CTO</li>
                  <li>
                    - 오픈소스 한국어 언어모델 자체개발 (<a
                      href="https://github.com/quantumaikr/KoreanLM"
                      target="_blank"
                      style="color: #ff4668"
                      >KoreanLM</a
                    >
                    /
                    <a
                      href="https://huggingface.co/quantumaikr/KoreanLM-3B"
                      target="_blank"
                      style="color: #ff4668"
                      >KoreanLM-3B</a
                    >)
                  </li>
                  <li>
                    - 23.08.10 : Open LLM Leaderboard 세계 7위 (<a
                      href="https://huggingface.co/quantumaikr/llama-2-70b-fb16-guanaco-1k"
                      target="_blank"
                      style="color: #ff4668"
                      >llama-2-70b-fb16-guanaco-1k</a
                    >)
                  </li>
                  <li>
                    - 23.08.02 : Open LLM Leaderboard 세계 25위 (<a
                      href="https://huggingface.co/quantumaikr/QuantumLM-70B-hf"
                      target="_blank"
                      style="color: #ff4668"
                      >LLama2 70B QLoRA 파인튜닝 </a
                    >)
                  </li>
                  <li>
                    <a
                      href="https://huggingface.co/spaces/HuggingFaceH4/open_llm_leaderboard"
                      target="_blank"
                      style="color: #1ea1fa"
                      >※ Open LLM Learderboard Link </a
                    >
                  </li>
                </ul>
                <br />
                <p># 강의 이력:</p>
                <ul>
                  <li>- 언어모델 실용강의 1차(36시간), 2차(26시간)</li>
                  <li>- 삼성전자 R&D센터 딥러닝 자연어처리 강의</li>
                  <li>- 국립중앙도서관 챗봇 에이전트 교육</li>
                  <li>- KTDS AI 개발팀 교육(자연어처리)</li>
                  <li>- AI 엔지니어 직무전환 교육</li>
                </ul>
              </div>
            </div>
            <!-- /Instructor -->

            <!-- Contact -->
            <div class="card overview-sec">
              <div class="card-body">
                <h5 class="subs-title">문의 사항</h5>
                <ul class="mb-0">
                  <li class="my-2">이메일: aiacademy131@gmail.com</li>
                  <li class="my-2">전화: 02-2039-0677</li>
                </ul>
              </div>
            </div>
            <!-- /Contact -->
          </div>
          
          <div class="col-lg-4">
            <div class="sidebar-sec">
              <!-- Video -->
              <div class="video-sec vid-bg">
                <div class="card">
                  <div class="card-body">
                  
                    <a
                      href="https://www.youtube.com/embed/LOf-XU6PPN0"
                      class="video-thumbnail"
                      data-fancybox=""
                    >
                      <div class="play-icon">
                        <i class="fa-solid fa-play"></i>
                      </div>
                      <img
                        class=""
                        :src="`${serverURL}${course.image.file}`"
                        alt=""
                      />
                    </a>
                    <div class="video-details">
                      <div class="course-fee">
                        <h2 style="color: #1e274b; font-size: 26px;">{{$filters.comma(discounted_price)}}원</h2>
                        <p>
                          <span style="color: #1e274b; font-size: 22px; font-weight: 600;">{{$filters.comma(course.price)}}원</span
                          ><i style="color: red; font-size: 12px;"> (<i style="font-size: 20px;">{{course.discount_rate}}%할인</i>, {{course.deadline}})</i
                          >
                        </p>
                      </div>
                      <!-- <router-link to="checkout" class="btn btn-enroll my-3 w-100">신청하기</router-link> -->
                      <router-link
                        class="btn btn-enrolled my-3 w-100"
                        v-if="enrolled"
                        :to="`course-lesson?c=${course.id}`"
                      >
                        수업 듣기
                      </router-link>
                      <a
                        href="javascript:;"
                        class="btn btn-enroll my-3 w-100"
                        @click.prevent="checkout()"
                        v-else
                        >신청 하기</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Video -->

              <!-- Include -->
              <div class="card include-sec">
                <div class="card-body">
                  <div class="cat-title">
                    <h4>포함된 내용</h4>
                  </div>
                  <ul>
                    <li>
                      <img
                        src="../../../../assets/img/icon/import.svg"
                        class="me-2"
                        alt=""
                      />
                      14시간 분량 영상
                    </li>
                    <li>
                      <img
                        src="../../../../assets/img/icon/play.svg"
                        class="me-2"
                        alt=""
                      />
                      20개 챕터 / 55 레슨
                    </li>
                    <li>
                      <img
                        src="../../../../assets/img/icon/key.svg"
                        class="me-2"
                        alt=""
                      />
                      6개월 이용권
                    </li>
                    <li>
                      <img
                        src="../../../../assets/img/course/course-detail-voucher.jpeg"
                        class="me-2"
                        style="width: 100% !important;"
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Include -->

              <!-- Features -->
              <div class="card feature-sec">
                <div class="card-body">
                  <div class="cat-title">
                    <h4>강의 수준</h4>
                  </div>
                  <ul>
                    <li>
                      <img
                        src="../../../../assets/img/icon/timer.svg"
                        class="me-2"
                        alt=""
                      />
                      학습 시간: <span>약 {{course.learning_duration_time}}시간 소요</span>
                    </li>
                    <li>
                      <img
                        src="../../../../assets/img/icon/chart.svg"
                        class="me-2"
                        alt=""
                      />
                      강의 레벨: <span>{{course.course_level}}</span>
                    </li>
                    <li>
                      <img
                        src="../../../../assets/img/icon/cart.svg"
                        class="me-2"
                        alt=""
                      />
                      사전 역량: <span>{{course.pre_competency}}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /Features -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Pricing Plan -->
    <!-- Youtube Course -->
    <section class="section new-course">
      <div class="container main">
        <div class="section-header aos" data-aos="fade-up">
          <div class="section-sub-head">
            <h2>최신 언어모델 유튜브 강의</h2>
          </div>
          <!-- <div class="all-btn all-category d-flex align-items-center">
                  <router-link to="course-list" class="btn btn-primary">All Courses</router-link>
              </div> -->
        </div>
        <!-- <div class="section-text aos" data-aos="fade-up">
              <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean accumsan bibendum gravida maecenas augue elementum et neque. Suspendisse imperdiet.</p>
          </div> -->
        <div class="course-feature">
          <div class="row">
            <div
              v-for="course_link, idx in youtube_courses"
              :key="idx"
              class="mobile-box col-lg-4 col-md-6 d-flex" 
            >
              <div
                class="video course-box d-flex aos"
                data-aos="fade-up"
              >
                <div class="video-container">
                  <iframe 
                      width="100%" height="100%" 
                      :src="'https://www.youtube.com/embed/'+course_link+'?controls=0'" 
                      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Youtube Course -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import dayjs from "dayjs";
import AOS from "aos";

export default {
  components: {},
  data() {
    return {
      content: "",
      enrolled: false,
      youtube_courses: [
        'vziygFrRlZ4',
        'jvYpv9VJBOA',
        'T1XadeiKl1M',
        'dLo4QkEq-Hg',
        'u2tQYgrLouo',
        // '8e8x5WYY8W4',
        // 'yl8rDLTWsg8',
        'ha9yYq7ulWQ',
        'TNFYUfM3IQA',
        'HewtI35-lp8',
        // 'bqZrlFoXVfA',
        'zN30JodATrU'
      ]
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    course() {
      return this.$store.state.course?.course;
    },
    courses() {
      return this.$store.state.course?.courses;
    },
    discounted_price() {
      let discount_price = 0
      discount_price = this.$store.state.course?.course.price * (1 - this.$store.state.course?.course.discount_rate/100) 
      discount_price = Math.round(discount_price / 1000) * 1000;
      return discount_price
    },
  },
  mounted() {
    this.$store.dispatch("course/course_list");
    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
    this.$store
      .dispatch("course/course_item", this.$route.query.id)
      .then((course) => {
        if (this.loggedIn) {
          this.$store.dispatch("course/mycourse_list").then(
            (courses) => {
              this.enrolled = courses.some(
                (element) => element.id === course.id
              );
            },
            (error) => {
              console.log(error);
            }
          );
        }
      });
  },
  methods: {
    hasPreview(lesson) {
      const hasPreview = lesson.lesson.sublesson.some(item => item.type === 1);
      return hasPreview
      
    },
    getPreview(sublesson) {
      if (this.loggedIn) {
        this.$router.push(
          `course-lesson` + `?c=${this.course.id}&sl=${sublesson.id}`
        );
      } else {
        this.$router.push(`login`);
      }
    },
    checkout() {
      if (this.loggedIn) {
        this.$router.push(`checkout?id=${this.course.id}`);
      } else {
        this.$router.push("login");
      }
    },
  },
};
</script>
