<template>
  <!-- Feature Course -->
  <section class="section new-course">
    <div class="container main">
      <div class="section-header aos" data-aos="fade-up">
        <div class="section-sub-head">
          <h2>유료 모델</h2>
        </div>
        <!-- <div class="all-btn all-category d-flex align-items-center">
                <router-link to="course-list" class="btn btn-primary">All Courses</router-link>
            </div> -->
      </div>
      <!-- <div class="section-text aos" data-aos="fade-up">
            <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean accumsan bibendum gravida maecenas augue elementum et neque. Suspendisse imperdiet.</p>
        </div> -->
      <div class="course-feature">
        <div class="row">
          <div
            v-for="product in products"
            :key="product.id"
            class="mobile-box col-lg-4 col-md-6 d-flex" 
          >
            <div
              class="course-box d-flex aos"
              :class="{disabled : product.status==1}"
              data-aos="fade-up"
            >
              <div class="product">
                <div class="product-img">
                  <router-link :to="`${getProductDetailPath(product.id)}`">
                    <img
                      v-if="product.image"
                      class="img-fluid"
                      :src="`${serverURL}${product.image.file}`"
                    />
                  </router-link>
                  <router-link 
                      :to="`${getProductDetailPath(product.id)}`"
                      class="price text-center active" v-if="product.status==2">
                      <h3>구입 문의</h3>
                  </router-link>
                  <!-- <div class="price text-center active" v-else>
                      <h3>22% 할인 중 (~9.18)</h3>
                  </div> -->
                </div>
                <div class="product-content">
                  <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                          <div class="course-name">
                              <h4><router-link :to="`${getProductDetailPath(product.id)}`">{{product.title}}</router-link></h4>
                              <p>{{product.intro}}</p>
                          </div>
                      </div>
                  </div>
                  <div class="all-btn all-category d-flex align-items-center">
                    <!-- <a v-if="course.status==1" class="btn btn-primary"
                      >{{ course.month }}월 오픈</a
                    > -->
                    <!-- <router-link v-else
                      :to="`course-details?id=${course.id}`" class="btn btn-primary active"
                      >더 알아보기</router-link
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Feature Course -->

    <!-- Feature Course -->
  <section class="section mt-5">
    <div class="container main">
      <div class="section-header aos" data-aos="fade-up">
        <div class="section-sub-head">
          <h2>웹툰: 언어모델 도입기 </h2>
        </div>
        <!-- <div class="all-btn all-category d-flex align-items-center">
                <router-link to="course-list" class="btn btn-primary">All Courses</router-link>
            </div> -->
      </div>
      <!-- <div class="section-text aos" data-aos="fade-up">
            <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean accumsan bibendum gravida maecenas augue elementum et neque. Suspendisse imperdiet.</p>
        </div> -->
      <div class="course-feature">
        <div class="row">
          <div
            v-for="blog in blogs"
            :key="blog.id"
            class="mobile-box col-lg-4 col-md-6 d-flex" 
          >
            <div
              class="course-box d-flex aos"
              :class="{disabled : blog.status==1}"
              data-aos="fade-up"
            >
              <div class="product">
                <div class="product-img">
                  <router-link :to="`${getBlogDetailPath(blog.id)}`">
                    <img
                      v-if="blog.image"
                      class="img-fluid"
                      :src="`${serverURL}${blog.image.file}`"
                    />
                  </router-link>
                  <router-link 
                      :to="`${getBlogDetailPath(blog.id)}`"
                      class="price text-center active" v-if="blog.status==2">
                      <h3>구입 문의</h3>
                  </router-link>
                  <!-- <div class="price text-center active" v-else>
                      <h3>22% 할인 중 (~9.18)</h3>
                  </div> -->
                </div>
                <div class="product-content">
                  <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                          <div class="course-name">
                              <h4><router-link :to="`${getBlogDetailPath(blog.id)}`">{{blog.title}}</router-link></h4>
                              <p>{{blog.intro}}</p>
                          </div>
                      </div>
                  </div>
                  <div class="all-btn all-category d-flex align-items-center">
                    <!-- <a v-if="course.status==1" class="btn btn-primary"
                      >{{ course.month }}월 오픈</a
                    > -->
                    <!-- <router-link v-else
                      :to="`course-details?id=${course.id}`" class="btn btn-primary active"
                      >더 알아보기</router-link
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Feature Course -->

  <!-- Master Skill -->
  <section class="section master-skill" v-if="windowWidth > 767">
    <div class="container main">
      <div class="row">
        <div class="col-lg-12 col-md-12 d-flex align-items-end">
          <div 
            class="aos" data-aos="fade-up" style="width:100%;">
            <img
              src="../../../assets/img/product/lm_store.jpeg"
              alt=""
               width="100%"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section master-skill" v-else>
    <div class="container main">
      <div class="row">
        <div class="col-lg-12 col-md-12 d-flex align-items-end">
          <div 
            class="aos" data-aos="fade-up" style="width:100%;">
            <img
              src="../../../assets/img/product/m_lm_store.jpeg"
              alt=""
               width="100%"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Master Skill -->

  <!-- Master Skill -->
  <!-- <section class="section master-skill">
    <div class="container main">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head d-block d-sm-none">
              <h2>언어모델 전문가로<br /> 성장하세요.</h2>
            </div>
            <div class="section-sub-head d-none d-sm-block">
              <h2>언어모델 전문가로 성장하세요.</h2>
            </div>
          </div>
          <div class="section-text aos" data-aos="fade-up">
            <p>
              이 프로그램은 이론과 실제의 균형을 맞추어, 참가자들이 새로운
              지식을 실질적인 프로젝트에 적용할 수 있도록 돕습니다. 이를 통해
              참가자들은 실무에서 직면하는 도전과제를 해결하는 능력을 키울 수
              있습니다.
            </p>
          </div>
          <div class="career-group aos" data-aos="fade-up">
            <div class="row">
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-1.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>언어모델 설계 전문가</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-2.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>도메인 파인튜닝 전문가</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-3.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>경량화 및 최적화 전문가</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-4.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>언어모델 운영 전문가</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 d-flex align-items-end">
          <div class="career-img aos" data-aos="fade-up">
            <img
              src="../../../assets/img/join_02.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- /Master Skill -->

</template>
<script>
import AOS from "aos";
import { ref, onMounted, onUnmounted, watchEffect } from 'vue';

export default {
  components: {},
  data() {
    return {
      content: "",
    };
  },
  setup() {
    const windowWidth = ref(window.innerWidth);
    // resize 이벤트 핸들러
    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      windowWidth
    };
  },
  computed: {
    products() {
      return this.$store.state.product.products;
    },
    blogs() {
      return this.$store.state.blog.blogs?.sort((a, b) => a.id - b.id);
    },
  },
  methods: {
    getProductDetailPath(product_id) {
       return 'product-details?id='+product_id
    },
    getBlogDetailPath(blog_id) {
       return 'blog-details?id='+blog_id
    },
    onReady() {
    },
  },
  mounted() {
    this.$store.dispatch("product/product_list");
    this.$store.dispatch("blog/blog_list", 'Web toon');
    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
  },
};
</script>
