<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <loginbanner></loginbanner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <div class="w-100">
              <div class="img-logo">
                <img
                  src="../../../assets/img/logo.png"
                  class="img-fluid"
                  alt="Logo"
                />
                <div class="back-home">
                  <router-link to="/">홈으로</router-link>
                </div>
              </div>
              <!-- <h1>로그인</h1> -->
              <Form @submit="handleLogin" :validation-schema="schema">
                <!-- <div class="form-group">
                  <label for="username" class="form-control-label">계정 ID</label>
                  <Field
                    name="username"
                    type="text"
                    class="form-control"
                    placeholder="Enter your username"
                  />
                  <ErrorMessage name="username" class="error-feedback" />
                </div> -->
                <!-- <div>
                  <label for="password" class="form-control-label">비밀번호</label>
                  <div class="pass-group">
                    <Field
                      name="password"
                      type="password"
                      class="form-control pass-input"
                      placeholder="Enter your password"
                    />
                    <ErrorMessage name="password" class="error-feedback" />
                    <span class="feather-eye toggle-password"></span>
                  </div>
                </div> -->
                
                <!-- <div class="forgot mt-2">
                  <span
                    ><router-link class="forgot-link" to="forgot-password"
                      >비밀번호 찾기</router-link
                    ></span
                  >
                </div> -->
                <!-- <div class="remember-me">
                  <label
                    class="custom_check mr-2 mb-0 d-inline-flex remember-me"
                  >
                    Remember me
                    <input type="checkbox" name="radio" />
                    <span class="checkmark"></span>
                  </label>
                </div> -->
                
                
                <!-- <div class="d-grid form-group">
                  <button
                    class="btn btn-primary btn-start"
                    :disabled="loading"
                  >
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span>로그인</span>
                  </button>
                </div> -->
                <div class="d-grid form-group">
                  <button
                    class="btn btn-google btn-success"
                    :disabled="loading"
                    @click.prevent="googleLogin()"
                  >
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span>구글 로그인</span>
                  </button>
                </div>
                <div class="d-grid form-group">
                  <button
                    class="btn btn-kakao btn-warning"
                    :disabled="loading"
                    @click.prevent="kakaoLogin()"
                  >
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    <span>카카오 로그인</span>
                  </button>
                </div>
                <!-- </GoogleLogin> -->
                
                <div class="form-group">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
                <div class="form-group">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
                <!-- <p class="text-center my-3"><router-link to="register"><i><small style="color: #39486c;">회원가입 이동?</small></i></router-link></p> -->

              </Form>
            </div>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>

import axios from 'axios'
import { googleAuthCodeLogin } from "vue3-google-login"


import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import Vue from "vue";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("계정 ID를 입력해주세요!"),
      password: yup.string().required("비밀번호를 입력해주세요!"),
    });

    return {
      prevRoute: null,
      loading: false,
      message: "",
      schema,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if(from.name != null) {
        localStorage.setItem('prev_page', from.fullPath)
      }
    })
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/setting-edit-profile");
    }
  },
  mounted() {
    if ($(".toggle-password").length > 0) {
      $(document).on("click", ".toggle-password", function () {
        $(this).toggleClass("feather-eye feather-eye-off");
        var input = $(".pass-input");
        if (input.attr("type") == "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });
    }

    if(this.$route.query.code) {
      this.loading = true;

      var action_name = ''
      if (this.$route.query.scope && this.$route.query.scope.includes('googleapis')) {
          action_name = 'auth/googleLogin'
      } else {
          action_name = 'auth/kakaoLogin'
      }

      this.$store.dispatch(action_name, this.$route.query.code).then(
        (user) => {
          this.loading = false;
          if(user.user.name == null || user.user.phone == null) {
            this.$router.push("/setting-edit-profile");
          } else {
            const fullPath = localStorage.getItem('prev_page')
            if(fullPath) {
              this.$router.push(fullPath)
            } else {
              this.$router.push('/')
            }
          }
        },
        (error) => {
          this.loading = false;
          this.message = "아이디와 패스워드를 다시한번 확인해주세요."
        }
      );
    }
  },
  methods: {
    googleLogin() {
      this.loading = true;
      window.location.href = this.GOOGLE_LOGIN_URL;
    },
    kakaoLogin() {
      this.loading = true;
      window.location.href = this.KAKAO_LOGIN_URL;
    },
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.loading = false;
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message = "아이디와 패스워드를 다시한번 확인해주세요."
        }
      );
    },
  },
};
</script>
