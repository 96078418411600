<template>
<!-- Deposit Modal -->
<div class="modal-styles" id="paymentfailmodal" tabindex="-1" aria-labelledby="addpaymentMethod" aria-hidden="true">
    <div class="modal-dialog small">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="addpaymentMethod">결제 실패</h5>
            </div>
            <div class="modal-body">
                <div class="addpaymethod-form add-course-info">
                    <form action="#">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mb-0">
                                    <label class="form-control-label">결제가 완료되지 않았습니다.<br>결제 정보를 다시 확인하거나 다른 결제 수단을 이용해 주세요.</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer me-auto">
                <!-- <router-link to="/" type="button" class="btn btn-modal-style btn-theme">확인</router-link> -->
                <!-- <router-link to="/checkout" type="button" class="btn btn-modal-style btn-cancel">확인</router-link> -->
            </div>
        </div>
    </div>
</div>	   
<!-- Deposit Modal -->
</template>
