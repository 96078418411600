<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <courseheader></courseheader>
    <!-- Course Lesson -->
    <section class="page-content course-sec course-message">
      <div class="container">
        <div class="student-widget message-student-widget">
          <div class="student-widget-group">
            <div class="col-md-12">
              <!-- <div class="add-compose">
                <a href="javascript:;" class="btn btn-primary"
                  ><i class="fa-solid fa-plus"></i> New</a
                >
              </div> -->
            </div>
            <div class="col-md-12">
              <div class="chat-window">
                <!-- Chat Left -->
                <div class="chat-cont-left">
                  <div class="chat-users-list">
                    <div class="chat-scroll">
                      <a href="javascript:void(0);" class="media d-flex">
                        <div class="media-img-wrap flex-shrink-0">
                          <div class="avatar avatar-away">
                            <img
                              src="../../../assets/img/logo.png"
                              alt="User Image"
                              class="avatar-img rounded-circle"
                            />
                          </div>
                        </div>
                        <div class="media-body flex-grow-1">
                          <div>
                            <div class="user-name">언어모델 실용강의</div>
                            <div class="user-last-chat">
                              언어모델에 대한 궁금증은 여기서...
                            </div>
                          </div>
                        </div>
                      </a>
                      <!-- <a
                        href="javascript:void(0);"
                        class="media read-chat active d-flex"
                      >
                        <div class="media-img-wrap flex-shrink-0">
                          <div class="avatar avatar-away">
                            <img
                              src="../../../assets/img/service-01.png"
                              alt="User Image"
                              class="avatar-img rounded-circle"
                            />
                          </div>
                        </div>
                        <div class="media-body flex-grow-1">
                          <div>
                            <div class="user-name">고객센터</div>
                            <div class="user-last-chat">
                              궁금하신게 있으신가요?
                            </div>
                          </div>
                        </div>
                      </a> -->
                    </div>
                  </div>
                </div>
                <!-- /Chat Left -->

                <!-- Chat Right -->
                <div class="chat-cont-right">
                  <div class="chat-header">
                    <a
                      id="back_user_list"
                      href="javascript:void(0)"
                      class="back-user-list"
                    >
                      <i class="material-icons">chevron_left</i>
                    </a>
                    <div class="media d-flex">
                      <div class="media-img-wrap flex-shrink-0">
                        <div class="avatar avatar-online">
                          <img
                            src="../../../assets/img/logo.png"
                            alt="User Image"
                            class="avatar-img rounded-circle"
                          />
                        </div>
                      </div>
                      <div class="media-body flex-grow-1">
                        <div class="user-name">언어모델 실용강의</div>
                      </div>
                    </div>
                  </div>
                  <div class="chat-body">
                    <div class="chat-scroll" ref="chatContainer">
                      <ul class="list-unstyled">
                        <li class="media d-flex" v-for="item, idx in messages" :key="idx" :class="item.type == 'sent'? 'sent': 'received'">
                          <div class="media-body flex-grow-1">
                            <div class="msg-box">
                              <div class="msg-bg">
                                <p v-dompurify-html="item.message"></p>
                              </div>
                              <ul class="chat-msg-info">
                                <li>
                                  <div class="chat-time">
                                    <span>{{item.time}}</span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="chat-footer">
                    <div class="input-group">
                      <div class="btn-file btn">
                        <i class="fa fa-paperclip"></i>
                        <input type="file" />
                      </div>
                      <input
                        type="text"
                        class="input-msg-send form-control"
                        placeholder="Type your message here..."
                        v-model="message"
                        @keydown.enter.prevent="sendMessage"
                        @compositionstart="compositionStart"
                        @compositionend="compositionEnd"
                      />
                      <button
                        type="button"
                        class="btn btn-primary msg-send-btn rounded-pill"
                        :disabled="loading"
                        @click="sendMessage"
                      >
                        <span
                          v-show="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <img src="../../../assets/img/send-icon.svg" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
                <!-- /Chat Right -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Course Lesson -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import dayjs from "dayjs";
import { ref, onUpdated, nextTick } from 'vue';

export default {
  data() {
    return {
      message: null,
      loading: false,
      messages: [],
      composing: false,
    };
  },
  setup() {
    const chatContainer = ref(null);
    
    onUpdated(() => {
      nextTick(() => {
        chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
      });
    });

    return { chatContainer }
  },
  computed: {
    message_list() {
      return this.$store.state.aiagent.messages;
    },
    currentUser() {
      return this.$store.state.auth.user?.user;
    },
  },
  mounted() {    
    this.$store.dispatch("aiagent/message_list", this.currentUser.id).then(()=> {
      
      
      this.message_list.forEach((item,index,arr2)=>{

        console.log('item', item);

        if(this.currentUser.id == item.author.id) {
          this.messages.push({
            type: 'sent',
            message: item.content,
            time: dayjs(item.created_at).format('M월 D일 A h시 m분')
          })
        } else {
          this.messages.push({
            type: 'received',
            message: item.content,
            time: dayjs(item.created_at).format('M월 D일 A h시 m분')
          })
        }
        
      })

      this.messages.push({
          type: 'received',
          message: "무엇을 도와드릴까요?",
          time: dayjs().format('M월 D일 A h시 m분')
        })
    });

  },
  methods: {
    compositionStart() {
      this.composing = true;
    },
    compositionEnd() {
      this.composing = false;
    },
    sendMessage() {
      if (!this.composing) {
        this.loading = true;

        this.messages.push({
          type: 'sent',
          message: this.message,
          time: dayjs().format('M월 D일 A h시 m분')
        })

        this.$store.dispatch("aiagent/chatting", { content: this.message }).then((response) => {
            this.loading = false;
            this.messages.push({
              type: 'received',
              message: response.content
            })
          },
          (error) => {
            this.loading = false;
          }
        );

        this.message = ''
      }
    }
  }
}
</script>
