<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>

    <!--Dashbord Student -->
    <div class="page-content">
      <div class="container" v-if="currentUser">
        <div class="row">
          <studentsidebar></studentsidebar>

          <!-- Profile Details -->
          <div class="col-xl-9 col-md-8">
            <div class="settings-widget profile-details">
              <div class="settings-menu p-0">
                <div class="profile-heading">
                  <h3>프로필 정보</h3>
                </div>
                <div class="checkout-form personal-address add-course-info">
                  <form action="#">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">ID</label>
                          <input
                            type="text"
                            class="form-control"
							disabled
                            :placeholder="currentUser.username"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">Email</label>
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="currentUser.email" disabled
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">이름</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="name"
                            placeholder="이름을 입력해주세요." 
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label">Phone</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="phone"
                            placeholder="- 없이 입력해주세요." 
                          />
                        </div>
                      </div>
                      <div class="update-profile">
                        <p style="color: red; font-size=12px;">{{errorMessage}}</p>
                        <button type="button" class="btn btn-primary" @click="updateProfile()">
                          프로필 수정
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Profile Details -->
        </div>
      </div>
    </div>
    <!-- /Dashbord Student -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import Vue from "vue";
export default {
  components: {},
  data() {
    return {
      name: '',
      phone: '',
      errorMessage: null,
      prev_timer: null,
    };
  },
  beforeUnmount() {
    if(this.prev_timer) {
      clearTimeout(this.prev_timer)
    }
  },
  computed: {
	  currentUser() {
      return this.$store.state.auth.user?.user;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if(this.loggedIn) {
      this.$store.dispatch('auth/check_auth').catch((error) => {
          this.$router.push("login");
        })
    }

    this.name = this.currentUser.name;
    this.phone = this.currentUser.phone;
  
    if(!this.name || !this.phone) {
      this.errorMessage = "이름과 전화번호를 입력해주세요."

      if(this.prev_timer) {
        clearTimeout(this.prev_timer)
      }
      this.prev_timer = setTimeout(() => {
        alert("이름과 전화번호를 입력해주세요.")  
      }, 2000);
    }  
  },
  methods: {
    updateProfile() {
      this.errorMessage = null

      if(!this.name && !this.phone) {
        this.errorMessage = "이름과 전화번호를 입력해주세요."
        return;
      } 
      if(!this.name) {
        this.errorMessage = "이름을 입력해주세요."
        return;
      }
      if(!this.phone) {
        this.errorMessage = "전화번호를 입력해주세요."
        return;
      }

      this.$store.dispatch("auth/update_profile", {name: this.name, phone: this.phone}).then(()=> {
        alert('프로필 정보가 수정되었습니다.')
      },
      (error) => {
        alert('입력정보를 다시한번 확인해주세요.')
        this.errorMessage = '입력정보를 다시한번 확인해주세요.'
      })
    }
  }
  
};
</script>
