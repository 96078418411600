<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <div class="row">
      <loginbanner></loginbanner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <div class="img-logo">
              <img
                src="../../../assets/img/logo.png"
                class="img-fluid"
                alt="Logo"
              />
              <div class="back-home">
                <router-link to="/">홈으로</router-link>
              </div>
            </div>
            <h1>회원가입</h1>
            <Form @submit="handleRegister" :validation-schema="schema">
              <div class="form-group">
                <label for="username" class="form-control-label">계정 ID</label>
                <Field
                  name="username"
                  type="text"
                  class="form-control"
                  placeholder="Enter your accound id"
                />
                <ErrorMessage name="username" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="name" class="form-control-label">이름</label>
                <Field
                  name="name"
                  type="text"
                  class="form-control"
                  placeholder="Enter your Full Name"
                />
                <ErrorMessage name="name" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="email" class="form-control-label">이메일</label>
                <Field
                  name="email"
                  type="email"
                  class="form-control"
                  placeholder="Enter your email address"
                />
                <ErrorMessage name="email" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="phone" class="form-control-label">전화번호</label>
                <Field
                  name="phone"
                  type="text"
                  class="form-control"
                  placeholder="Enter your phone number"
                />
                <ErrorMessage name="phone" class="error-feedback" />
              </div>

              <div class="form-group">
                <label for="password1" class="form-control-label"
                  >비밀번호</label
                >
                <div class="pass-group" id="passwordInput">
                  <Field
                    name="password1"
                    type="password"
                    class="form-control pass-input"
                    placeholder="Enter your password"
                  />
                  <ErrorMessage name="password1" class="error-feedback" />
                </div>
              </div>
              <div class="form-group">
                <label for="password2" class="form-control-label"
                  >비밀번호 확인</label
                >
                <div class="pass-group" id="passwordInput">
                  <Field
                    name="password2"
                    type="password"
                    class="form-control pass-input"
                    placeholder="Enter your password"
                  />
                  <ErrorMessage name="password2" class="error-feedback" />
                </div>
              </div>

              <div class="form-check remember-me">
                <label class="form-check-label mb-0">
                  <!-- <input
                    class="form-check-input"
                    type="checkbox"
                    name="remember"
                  /> -->
                  I agree to the
                  <router-link to="term-condition"
                    >Terms of Service</router-link
                  >
                  and
                  <router-link to="privacy-policy">Privacy Policy.</router-link>
                </label>
              </div>
              <div class="d-grid">
                <button class="btn btn-primary btn-start" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  회원 가입
                </button>
              </div>
            </Form>
            <div
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'"
            >
              {{ message }}
            </div>
            <p class="text-center my-3"><router-link to="login"><i><small style="color: #39486c;">로그인 이동?</small></i></router-link></p>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

import Vue from "vue";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup
        .string()
        .required("계정 ID를 입력해주세요!")
        .min(3, "최소 3자 입니다!")
        .max(20, "최대 20자 입니다."),
      name: yup
        .string()
        .required("이름을 입력해주세요!")
        .min(2, "최소 2자 입니다!")
        .max(10, "최대 10자 입니다."),
      email: yup
        .string()
        .required("이메일을 입력해주세요!")
        .email("이메일 형식을 맞춰주세요!")
        .max(40, "최대 40자 입니다."),
      phone: yup.string().required("연락처를 입력해주세요!"),
      password1: yup
        .string()
        .required("비밀번호를 입력해주세요!")
        .min(6, "비밀번호는 최소 6자 입니다!")
        .max(40, "비밀번호는 최대 40자 입니다!"),
      password2: yup
        .string()
        .required("비밀번호를 입력해주세요!")
        .min(6, "비밀번호는 최소 6자 입니다!")
        .max(40, "비밀번호는 최대 40자 입니다!"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    console.log('this.loggedIn', this.loggedIn);
      
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;

      if(user.password1 != user.password2) {
          this.message = '비밀번호를 다시한번 확인해주세요.'
          this.successful = false;
          this.loading = false;
          return
      }
      

      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.$router.push("/");
          
          // this.message = data.message;
          // this.successful = true;
          // this.loading = false;
        },
        (error) => {
          this.message = '중복된 ID 혹은 이메일입니다. 다시한번 확인해주세요.'
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>
