<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>

    <!-- Course Lesson -->
    <section class="page-content course-sec course-lesson">
      <div class="container" v-if="course && sublesson">
        <div class="row">
          <div class="col-lg-3 px-2" >
            <!-- Course Lesson -->
            <div class="lesson-group">
              <div class="course-card" v-for="lesson, idx in course.course_lessons">
                <h6 class="cou-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    :href="`#collapse`+idx"
                    aria-expanded="false"
                    >{{lesson.lesson.title}}
                  </a>
                </h6>
                <div :id="`collapse`+idx" class="card-collapse" :class="isCurrentLesson(lesson.lesson)? '':'collapse'" style="" v-if="sublesson">
                  <ul>
                    <li v-for="item, idx in lesson.lesson.sublesson">
                      <!-- <a :href="`course-lesson`+`?c=${course.id}&sl=${item.id}`">
                        <p :class="item.id == sublesson.id? 'play-intro': ''">{{item.title}}</p>
                      </a> -->
                      <a href="javascript:;" @click="toSubLesson(item)">
                        <p :class="item.id == sublesson.id? 'play-intro': ''">{{item.title}}</p>
                      </a>
                      <div>
                        <img
                          v-if="item.id == sublesson.id"
                          src="../../../assets/img/icon/play-icon.svg"
                          alt=""
                        />
                        <img
                          v-else-if="isCompleted(item.id)"
                          src="../../../assets/img/icon/check-circle.svg"
                          alt=""
                        />
                        <a href="javascript:;" @click="toSubLesson(item)" v-else-if="!enrolled && item.type">Preview</a>

                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- /Course Lesson -->
          </div>
          <div class="col-lg-9 px-2" style="margin-bottom: 20px;">
            <!-- Introduction -->
            <div class="student-widget lesson-introduction" v-if="sublesson">
              <div class="lesson-widget-group">
                <!-- <h4 class="tittle">Introduction</h4> -->
                <div class="introduct-video">
                  <div style="padding: 56.47% 0 0 0; position: relative">
                    <!-- {{sublesson.video}} -->
                    <iframe
                      :src="`https://player.vimeo.com/video/${videoUrl}?badge=0&amp;autopause=0&amp;player_id=0&amp;`"
                      frameborder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowfullscreen
                      style="
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                      "
                      :title="sublesson.title"
                    ></iframe>
                  </div>
                </div>
                
              </div>
              <div class="lesson-widget-group">
                <div v-dompurify-html="sublesson.summary" v-if="enrolled || !sublesson.type"></div>

                <a
                  href="javascript:;"
                  class="btn btn-enroll m-0 "
                  style="width: 150px;"
                  @click.prevent="checkout()"
                  v-if="!enrolled"
                  >강의 신청</a
                >
              </div>
            </div>

            <!-- /Introduction -->
          </div>
        </div>
      </div>
    </section>
    <!-- /Course Lesson -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import dayjs from "dayjs";
import AOS from "aos";

export default {
  components: {},
  data() {
    return {
      enrolled: false,
      lesson_id: null,
      prev_sublesson: null,
      prev_timer: null,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    course() {
      return this.$store.state.course?.course;
    },
    sublesson() {
      if( this.prev_sublesson != this.$store.state.course?.sublesson?.id) {
        this.prev_sublesson = this.$store.state.course?.sublesson.id
        this.getHistory()

        if(this.prev_timer) {
          clearTimeout(this.prev_timer)
        }
        this.prev_timer = setTimeout(() => {
          this.saveHistory()
        }, 20_000);
      }
      return this.$store.state.course?.sublesson;
    },
    history() {
      return this.$store.state.course?.history;
    },
    videoUrl(){
      if(!this.enrolled && this.sublesson.type) {
        return this.sublesson.prev_video
      } else {
        return this.sublesson.video
      }
    }
  },
  beforeUnmount() {
    if(this.prev_timer) {
      clearTimeout(this.prev_timer)
    }
  },
  destroyed() {
    console.log('destroyed..');
  },
  mounted() {
    this.$store.dispatch("course/course_item", this.$route.query.c)
        .then((course) => {
          if(this.$route.query.sl) {
            this.$store.dispatch("course/sublesson_item", this.$route.query.sl)
                    .then(
                      (data) => {},
                      (error) => {
                        alert('권한이 없습니다.')
                        this.$router.push("/");
                      }
                    )
          } else {
            var sublession_id = null;
            sublession_id = localStorage.getItem('last_sublesson_id');
            if(sublession_id == null) {
              sublession_id = course.course_lessons[0].lesson.sublesson[0].id
            }
            this.$store.dispatch("course/sublesson_item", sublession_id)
                    .then(
                      (data) => {},
                      (error) => {
                        alert('권한이 없습니다.')
                        this.$router.push("/");
                      }
                    )
          }
        }, (error) => {
        })

    this.$store.dispatch("course/mycourse_list").then((courses)=>{
      this.enrolled = courses.some((element) => element.id == this.$route.query.c)
    });
  },
  methods: {
    isCompleted(sublesson_id) {
      return this.history?.some((element)=>element.card_id === sublesson_id)
    },
    checkout() {
      if (this.loggedIn) {
        this.$router.push(`checkout?id=${this.course.id}`);
      } else {
        this.$router.push("login");
      }
    },
    saveHistory() {
      const payload = {
        course_id: this.course.id,
        lesson_id: this.lesson_id,
        sublesson_id: this.sublesson.id
      }
      this.$store.dispatch("course/create_history", payload)
    },
    getHistory() {
      this.$store.dispatch("course/get_history")
    },
    toSubLesson(sublesson) {
      if(this.enrolled || sublesson.type) { 
        this.$store.dispatch("course/sublesson_item", sublesson.id)
              .then(
                (data) => {
                  localStorage.setItem('last_course_id', JSON.stringify(this.course.id));
                  localStorage.setItem('last_sublesson_id', JSON.stringify(sublesson.id));
                },
                (error) => {
                  alert('권한이 없습니다.')
                  this.$router.push("/");
                }
              )
      } else {
        this.$router.push(`checkout?id=${this.$route.query.c}`);
      }
      
    },
    isCurrentLesson(lesson) {
      return lesson.sublesson.some((sublesson) => {
        if(sublesson.id == this.sublesson.id) {
          this.lesson_id = lesson.id
          return true
        } else {
          return false
        }
        
      })
    },
    checkout() {
      if (this.loggedIn) {
        this.$router.push(`checkout?id=${this.course.id}`);
      } else {
        this.$router.push("register");
      }
    },
  },
};
</script>
