import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.BASE_URL || 'http://localhost:8000/api'


class BlogService {
  getBlogList(category) {
    if (category) {
      return axios.get(API_URL + `/blog/blogs/${category}/`);
    } else {
      return axios.get(API_URL + '/blog/blogs/');
    }
    
  };
  getBlog(id) {
    return axios.get(API_URL + `/blog/${id}/`);
  };
  comment(id, payload) {
    return axios.post(API_URL + `/blog/${id}/comment/`, {
      content: payload.content
    }, {
      headers: authHeader()
    });
  };
}
export default new BlogService();