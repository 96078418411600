import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.BASE_URL || 'http://localhost:8000/api'

class AuthService {
  
  kakaoLogin(code) {
    return axios
      .post(API_URL + '/social-login/kakao/', {
        code: code
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  googleLogin(code) {
    return axios
      .post(API_URL + '/social-login/google/', {
        code: code
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  checkAuth() {
    return axios
      .get(API_URL + '/user/', {
        headers: authHeader()
      })
  }

  login(user) {
    return axios
      .post(API_URL + '/auth/login/', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + '/auth/registration/', {
      username: user.username,
      name: user.name,
      email: user.email,
      phone: user.phone,
      password1: user.password1,
      password2: user.password2,
    })
    .then(response => {
      if (response.data.access_token) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
  }

  passwordReset(email) {
    return axios.patch(API_URL + '/user/password/reset/', {
      email: email
    });
  }

  passwordChange(data) {
    return axios.post(API_URL + '/auth/password/change/', data, {
      headers: authHeader()
    });
  }

  updateProfile(data) {
    return axios.patch(API_URL + '/user/', data, {
      headers: authHeader()
    });
  }
}

export default new AuthService();