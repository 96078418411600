<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>
    <!-- <coursedetails></coursedetails> -->
    <product-inner-page lesson_number="한국어" time_number="파인튜닝" class="mt-3"></product-inner-page>
    <!-- Course Content -->
    <section class="page-content course-sec">
      <div class="container" v-if="product">
        <div class="row">
          <div class="col-lg-4">
            <div class="">
              <!-- Video -->
              <div class="video-sec vid-bg">
                <div class="card">
                  <div class="card-body">
                    <img
                        class=""
                        :src="`${serverURL}${product.image.file}`"
                        alt=""
                      />
                  
                    <!-- <a
                      href="https://www.youtube.com/embed/LOf-XU6PPN0"
                      class="video-thumbnail"
                      data-fancybox=""
                    >
                      <div class="play-icon">
                        <i class="fa-solid fa-play"></i>
                      </div>
                      <img
                        class=""
                        :src="`${serverURL}${product.image.file}`"
                        alt=""
                      />
                    </a> -->
                    <div class="video-details">
                      <div class="course-fee">
                        <h2 style="color: #1e274b; font-size: 26px; width:100%;">{{$filters.comma(discounted_price)}}원</h2>
                        <p>
                          <span style="color: #1e274b; font-size: 22px; font-weight: 600;">{{$filters.comma(product.price)}}원</span
                          ><i style="color: red; font-size: 12px;"> (<i style="font-size: 20px;">{{product.discount_rate}}%할인</i>, {{product.deadline}})</i
                          >
                        </p>
                      </div>
                      <!-- <router-link to="checkout" class="btn btn-enroll my-3 w-100">신청하기</router-link> -->
                      <!-- <router-link
                        class="btn btn-enrolled my-3 w-100"
                        v-if="enrolled"
                        :to="`course-lesson?c=${product.id}`"
                      >
                        다운로드
                      </router-link> -->
                      <!-- <a
                        href="https://huggingface.co/quantumaikr/KoreanLM-3B/resolve/main/model-00001-of-00004.safetensors"
                        class="btn btn-enrolled my-3 w-100"
                        v-if="enrolled"
                        :download=product.title
                      >
                        다운로드
                      </a> -->
                      <a
                        href="javascript:;"
                        class="btn btn-enrolled my-3 w-100"
                        @click.prevent="download()"
                        v-if="enrolled"
                        >다운로드</a
                      >
                      <a
                        href="javascript:;"
                        class="btn btn-enroll my-3 w-100"
                        @click.prevent="checkout()"
                        v-else
                        >구입 하기</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Video -->

              <!-- Include -->
              <!-- <div class="card include-sec">
                <div class="card-body">
                  <div class="cat-title">
                    <h4>모델 정보</h4>
                  </div>
                  <ul>
                    <li>
                      <img
                        src="../../../../assets/img/icon/import.svg"
                        class="me-2"
                        alt=""
                      />
                      Model Name: Llama-2-70B-Korean
                    </li>
                    <li>
                      <img
                        src="../../../../assets/img/icon/play.svg"
                        class="me-2"
                        alt=""
                      />
                      Base Model: Llama-2-70B
                    </li>
                    <li>
                      <img
                        src="../../../../assets/img/icon/key.svg"
                        class="me-2"
                        alt=""
                      />
                      Provider: Meta
                    </li>
                  </ul>
                </div>
              </div> -->
              <!-- /Include -->

              <!-- Features -->
              <!-- <div class="card feature-sec">
                <div class="card-body">
                  <div class="cat-title">
                    <h4>학습 정보</h4>
                  </div>
                  <ul>
                    <li>
                      <img
                        src="../../../../assets/img/icon/timer.svg"
                        class="me-2"
                        alt=""
                      />
                      Train Time: <span>약 100시간 소요</span>
                    </li>
                    <li>
                      <img
                        src="../../../../assets/img/icon/chart.svg"
                        class="me-2"
                        alt=""
                      />
                      GPU used: <span>A100</span>
                    </li>
                    <li>
                      <img
                        src="../../../../assets/img/icon/cart.svg"
                        class="me-2"
                        alt=""
                      />
                      Data size: <span>1GB (100Million Tokens)</span>
                    </li>
                  </ul>
                </div>
              </div> -->
              <!-- /Features -->
            </div>
          </div>

          <div class="col-lg-8">

            <!-- Overview -->
            <div class="card overview-sec">
              <div class="card-body">
                <h5 class="subs-title">모델 소개</h5>
                <!-- <div class="video-container mb-4">
                  <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${course.video_url}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div> -->
                <div v-dompurify-html="product.describe"></div>
              </div>
            </div>
            <!-- /Overview -->
            
            <!-- Contact -->
            <div class="card overview-sec">
              <div class="card-body">
                <h5 class="subs-title">문의 사항</h5>
                <ul class="mb-0">
                  <li class="my-2">이메일: hi@quantumai.kr</li>
                  <li class="my-2">전화: 02-2039-0677</li>
                </ul>
              </div>
            </div>
            <!-- /Contact -->
          </div>
        </div>
      </div>
    </section>
    <!-- /Pricing Plan -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>

<script>
import dayjs from "dayjs";
import AOS from "aos";

export default {
  components: {},
  data() {
    return {
      content: "",
      enrolled: false,
      youtube_courses: [
        'vziygFrRlZ4',
        'jvYpv9VJBOA',
        'T1XadeiKl1M',
        'dLo4QkEq-Hg',
        'u2tQYgrLouo',
        // '8e8x5WYY8W4',
        // 'yl8rDLTWsg8',
        'ha9yYq7ulWQ',
        'TNFYUfM3IQA',
        'HewtI35-lp8',
        // 'bqZrlFoXVfA',
        'zN30JodATrU'
      ]
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    product() {
      return this.$store.state.product?.product;
    },
    products() {
      return this.$store.state.product?.products;
    },
    discounted_price() {
      let discount_price = 0
      discount_price = this.$store.state.product?.product.price * (1 - this.$store.state.product?.product.discount_rate/100) 
      discount_price = Math.round(discount_price / 1000) * 1000;
      return discount_price
    },
  },
  mounted() {
    this.$store.dispatch("product/product_list");
    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
    this.$store
      .dispatch("product/product_item", this.$route.query.id)
      .then((product) => {
        if (this.loggedIn) {
          this.$store.dispatch("product/myproduct_list").then(
            (products) => {
              this.enrolled = products.some(
                (element) => element.id === product.id
              );
            },
            (error) => {
              console.log(error);
            }
          );
        }
      });
  },
  methods: {
    download() {
      const url = this.product.download_link;
      const filename = this.product.title
      // Create an anchor element
      var a = document.createElement("a");
      a.href = url;
      a.download = filename || "download";

      // Append the anchor to the body (this is required for Firefox)
      document.body.appendChild(a);

      // Trigger a click event on the anchor
      a.click();

      // Remove the anchor from the body
      document.body.removeChild(a);
    },
    checkout() {
      if (this.loggedIn) {
        this.$router.push(`checkout-product?id=${this.product.id}`);
      } else {
        this.$router.push("login");
      }
    },
  },
};
</script>
