<template>
<!-- Inner Banner -->
<div class="inner-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-8" v-if="product">
                <h2>{{product.title}}</h2>
                <div v-dompurify-html="product.summary"></div>
                <!-- <div class="instructor-wrap border-bottom-0 my-3">
                    <div class="about-instructor align-items-center">
                        <div class="rating mb-0">							
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"><span>4.5</span> (15)</span>
                        </div>
                    </div>
                </div> -->
                
                <div class="course-info d-flex align-items-center border-bottom-0 my-3 p-0">
                    <div class="cou-info">
                        <img src="../../../../assets/img/icon/icon-01.svg" alt="">
                        <p>{{lesson_number}}</p>
                    </div>
                    <div class="cou-info">
                        <img src="../../../../assets/img/icon/timer-icon.svg" alt="">
                        <p>{{time_number}}</p>
                    </div>
                    <!-- <div class="cou-info">
                        <img src="../../../../assets/img/icon/people.svg" alt="">
                        <p>32 students enrolled</p>
                    </div> -->
                </div>							
            </div>
        </div>
    </div>
</div>
<!-- /Inner Banner -->
</template>

<script>
import dayjs from 'dayjs'

export default {
  components: {},
  props: {
    lesson_number: String,
    time_number: String
  },
  data() {
    return {
    };
  },
  computed: {
    product() {
      return this.$store.state.product.product;
    },
  },
}
</script>