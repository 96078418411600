<template>
  <div id="app">
    <loader v-if="!status"></loader>
    <metainfo />
    <router-view/>
  </div>
</template>
<script>
import { useMeta } from 'vue-meta'
export default {
  name: 'App',
  data: function() { return { status: false } },
}
</script>


