<template>
    <div class="navbar-header">
        <a id="mobile_btn" href="javascript:void(0);">
            <span class="bar-icon">
                <span></span>
                <span></span>
                <span></span>
            </span>
        </a>
        <router-link to="/" class="navbar-brand logo">
            <img src="../assets/img/logo.png" class="img-fluid" alt="Logo">
        </router-link>
    </div>
</template>