<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        
        <layoutslogin></layoutslogin>
       
        <blogdetails></blogdetails>

        <!-- Blog Details -->
        <section class="course-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 col-md-12">

                        <!-- Blog Post -->
                        <div class="blog" v-if="blog">
                            <div 
                              v-if="!noHeadCategories.includes(blog.category)"
                              class="blog-image">
                                <img 
                                    class="img-fluid" 
                                    v-if="blog.image"
                                    :src="`${serverURL}${blog.image.file}`">
                            </div>
                            <div class="blog-info clearfix">
                                <div class="post-left">
                                    <ul>
                                        <li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">{{created_at(blog)}}</li>
                                        <li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">{{blog.category}}</li>
                                    </ul>
                                </div>
                            </div>
                            <h3 class="blog-title">{{blog.title}}</h3>
                            <div class="blog-content">
                                <div v-dompurify-html="blog.content"></div>
                            </div>
                        </div>
                        <!-- /Blog Post -->
                        
                    </div>
                    
                    <blogsidebar></blogsidebar>
                    
                </div>
            </div>
        </section>
        <!-- /Blog Details -->
        
      <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
    </template>

    <script>
import dayjs from "dayjs";
import AOS from "aos";
import { useMeta } from 'vue-meta'

export default {
  components: {},
  data() {
    return {
      content: "",
      noHeadCategories: ['Web toon']
    };
  },
  computed: {
    blog() {
      return this.$store.state.blog?.blog;
    },
  },
  methods: {
    created_at(item) {
        return dayjs(item.created_at).format('M월 D일 A h시 m분')
    },
  },
  mounted() {
    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
    this.$store.dispatch("blog/blog_item", this.$route.query.id)
  },
};
</script>
