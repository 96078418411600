<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>

    <!--Dashbord Student -->
    <div class="page-content">
      <div class="container" v-if="currentUser">
        <div class="row">
          <studentsidebar></studentsidebar>

          <!-- Profile Details -->
          <div class="col-xl-9 col-md-8">
            <div class="settings-widget profile-details">
              <div class="settings-menu p-0">
                <div class="profile-heading">
                  <h3>비밀번호 수정</h3>
                </div>
                <div class="checkout-form personal-address add-course-info">
                  <form action="#">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">현재 비밀번호</label>
                          <input
                            type="password"
                            class="form-control"
                            placeholder="현재 비밀번호 입력"
                            v-model="formData.old_password"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-label">신규 비밀번호</label>
                          <input
                            type="password"
                            class="form-control"
                            placeholder="비밀번호 입력"
                            v-model="formData.new_password1"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-label">신규 비밀번호 확인</label>
                          <input
                            type="password"
                            class="form-control"
                            placeholder="비밀번호 입력"
                            v-model="formData.new_password2"
                          />
                        </div>
                      </div>
                      <div class="update-profile">
                        <p style="color: red; font-size=12px;">{{errorMessage}}</p>
                        <button type="button" class="btn btn-primary" @click="changePassword()">
                          비밀번호 수정
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- Profile Details -->
        </div>
      </div>
    </div>
    <!-- /Dashbord Student -->

    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import Vue from "vue";
export default {
  components: {},
  data() {
    return {
      formData: {
        old_password: null,
        new_password1: null,
        new_password2: null,
      },
      errorMessage:null
    };
  },
  computed: {
	  currentUser() {
      return this.$store.state.auth.user?.user;
    },
  },
  mounted() {},
  methods: {
    changePassword() {
      // this.errorMessage = null
      if(!this.formData.old_password || !this.formData.new_password1 || !this.formData.new_password2) {
        this.errorMessage = "비밀번호를 입력해주세요."
        return;
      }
      if(this.formData.new_password1 != this.formData.new_password2) {
        this.errorMessage = '신규 비밀번호가 일치하지 않습니다.'
        return;
      }

      this.$store.dispatch("auth/password_change", this.formData).then(()=> {
        alert('비밀번호가 변경되었습니다.')
      },
      (error) => {
        alert('비밀번호를 다시한번 확인해주세요.')
        this.errorMessage = '비밀번호를 다시한번 확인해주세요.'
      })
    }
  }
};
</script>
