import CourseService from "../services/course.service";

const initialState = {
  mycourses: null,
  courses: null,
  course: null,
  sublesson: null,
  history: null,
}
  
export const course = {
  namespaced: true,
  state: initialState,
  actions: {
    course_list({ commit }, org) {
      return CourseService.getPublicCourseList(org).then(
        (response) => {
          commit("courseListSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("courseListFailure");
          return Promise.reject(error);
        }
      );
    },
    course_item({ commit }, id) {
      return CourseService.getPublicCourse(id).then(
        (response) => {
          commit("courseSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("courseFailure");
          return Promise.reject(error);
        }
      );
    },
    sublesson_item({ commit }, id) {
      return CourseService.getSubLesson(id).then(
        (response) => {
          commit("subLessonSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    mycourse_list({ commit }, org) {
      return CourseService.getMyCourseList(org).then(
        (response) => {
          commit("mycourseListSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("mycourseListSuccess");
          return Promise.reject(error);
        }
      );
    },
    join_course({commit}, id) {
      return CourseService.joinCourseRequest(id).then(
        (response) => {
          commit("courseSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("courseFailure");
          return Promise.reject(error);
        }
      );
    },
    create_history({commit}, payload) {
      return CourseService.createHistory(payload).then(
        (response) => {
          commit("courseListSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("courseFailure");
          return Promise.reject(error);
        }
      );
    },
    get_history({commit}) {
      return CourseService.getHistory().then(
        (response) => {
          commit("getHistorySuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          // commit("courseFailure");
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    getHistorySuccess(state, history) {
      state.history = history
    },
    courseListSuccess(state, courses) {
      state.courses = courses;
    },
    courseListFailure(state) {
      state.courses = null;
    },
    mycourseListSuccess(state, courses) {
      state.mycourses = courses;
    },
    mycourseListFailure(state) {
      state.mycourses = null;
    },
    courseSuccess(state, course) {
      state.course = course
    },
    subLessonSuccess(state, sublesson) {
      state.sublesson = sublesson
    },
  },
};
