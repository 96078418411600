import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.BASE_URL || 'http://localhost:8000/api'


class ProductService {
  getPublicProductList() {
    return axios.get(API_URL + '/product/products/');
  };
  getPublicProduct(id) {
    return axios.get(API_URL + `/product/${id}/`);
  };
  getMyProductList() {
    return axios.get(API_URL + `/product/myproducts/`, {
      headers: authHeader()
    });
  };
  joinProductRequest(id) {
    return axios.patch(API_URL + `/product/${id}/join/`, {}, {
      headers: authHeader()
    });
  }
}

export default new ProductService();