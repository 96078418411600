<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        
        <layouts2></layouts2>
       
            <faqbreadcrumb></faqbreadcrumb>
			
			<!-- Help Details -->
			<div class="help-sec">
				<div class="container">
					<div class="row">
						<div class="col-lg-6">
						
							<!-- Faq -->							
							<div class="faq-card">
								<h6 class="faq-title">
									<a class="collapsed" data-bs-toggle="collapse" href="#faqone" aria-expanded="false">
									어떻게 신청하나요?</a>
								</h6>
								<div id="faqone" class="collapse">
									<div class="faq-detail">
										<p>quantumai.kr 사이트에서 회원가입하시고 각 강의 안내 페이지를 통해 결제해주십시오. 카드와 계좌입금이 가능합니다.</p>
									</div>
								</div>
							</div>
							
														
							<div class="faq-card">
								<h6 class="faq-title">
									<a class="collapsed" data-bs-toggle="collapse" href="#faqtwo" aria-expanded="false">
									내일배움카드 등 국비 과정인가요?</a>
								</h6>
								<div id="faqtwo" class="collapse">
									<div class="faq-detail">
										<p>국비과정이 아니므로 내일배움카드로 결제되지 않습니다.</p>
									</div>
								</div>
							</div>
							
														
							<div class="faq-card">
								<h6 class="faq-title">
									<a class="collapsed" data-bs-toggle="collapse" href="#faqthree" aria-expanded="false">
									강의는 어떻게 진행되나요?</a>
								</h6>
								<div id="faqthree" class="collapse">
									<div class="faq-detail">
										<p>모든 수업은 온라인 녹화강의로 진행합니다. 수강기간 동안 언제 어디서나 수강 가능합니다.</p>
									</div>
								</div>
							</div>
							<!-- /Faq -->
						
						</div>	
						
						<div class="col-lg-6">
						
							<!-- Faq -->							
							<div class="faq-card">
								<h6 class="faq-title">
									<a class="collapsed" data-bs-toggle="collapse" href="#faqfour" aria-expanded="false">
									무료 체험이 가능한가요?</a>
								</h6>
								<div id="faqfour" class="collapse">
									<div class="faq-detail">
										<p>해당 커리큘럼의 이전 강의가 <strong><a href="https://www.youtube.com/watch?v=vziygFrRlZ4&list=PLhZpGghaa_0QhRZBk6ALlTDM5MlM66Hqj" target="_blank">유튜브</a></strong>에 일부 공개되어 있습니다. 참고하시기 바랍니다. 본 강의에는 업그레이드된 내용이 다시 녹화되어 있습니다.</p>
									</div>
								</div>
							</div>
							
														
							<div class="faq-card">
								<h6 class="faq-title">
									<a class="collapsed" data-bs-toggle="collapse" href="#faqfive" aria-expanded="false">
									강의 수강에 가장 좋은 브라우저는 무엇인가요?</a>
								</h6>
								<div id="faqfive" class="collapse">
									<div class="faq-detail">
										<p>어느 브라우저이든 상관없습니다.</p>
									</div>
								</div>
							</div>
							
														
							<div class="faq-card">
								<h6 class="faq-title">
									<a class="collapsed" data-bs-toggle="collapse" href="#faqsix" aria-expanded="false">
									환불하려면 어떻게 해야하나요?</a>
								</h6>
								<div id="faqsix" class="collapse">
									<div class="faq-detail">
										<p>ai@ai-academy.ai로 메일주시면 처리해드리겠습니다.</p>
									</div>
								</div>
							</div>
							<!-- /Faq -->
						
						</div>	
					</div>
				</div>
			</div>
			<!-- /Help Details -->
        
        <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
</template>
