<template>
  <!-- sidebar -->
  <div class="col-xl-3 col-md-4 theiaStickySidebar">
    <div class="stickysidebar" v-if="currentUser">
      <div class="settings-widget dash-profile mb-3">
        <div class="settings-menu p-0">
          <div class="profile-bg">
            <h5>Beginner</h5>
          </div>
          <div class="profile-group">
            <div class="profile-name text-center">
              <h4>
                <a v-if="currentUser.name" href="javascript:;">{{currentUser.name}}</a>
                <a v-else href="javascript:;">{{currentUser.username}}</a>
              </h4>
              <p>{{currentUser.username}}</p>
            </div>
            <!-- <div class="go-dashboard text-center">
              <router-link
                to="deposit-student-dashboard"
                class="btn btn-primary"
                >Go to Dashboard</router-link
              >
            </div> -->
          </div>
        </div>
      </div>
      <div class="settings-widget account-settings">
        <div class="settings-menu">
          <h3>계정 설정</h3>
          <ul>
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'setting-edit-profile' ? 'active' : 'notactive'
                "
                to="setting-edit-profile"
                class="nav-link"
              >
                <i class="feather-settings"></i> 프로필
              </router-link>
            </li>
            <!-- <li class="nav-item" v-if="!currentUser.is_social_login">
              <router-link
                :class="
                  currentPath == 'setting-edit-password' ? 'active' : 'notactive'
                "
                to="setting-edit-password"
                class="nav-link"
              >
                <i class="feather-settings"></i> 비밀번호 수정
              </router-link>
            </li> -->
            <li class="nav-item">
              <a
                @click="logOut()"
                :class="currentPath == 'login' ? 'active' : 'notactive'"
                href="javascript:;"
                class="nav-link"
              >
                <i class="feather-power"></i> 로그아웃
              </a>
            </li>
          </ul>
          <h3>결제 정보</h3>
          <ul>
            <li class="nav-item">
              <router-link
                :class="
                  currentPath == 'setting-student-payment'
                    ? 'active'
                    : 'notactive'
                "
                to="setting-student-payment"
                class="nav-link"
              >
                <i class="feather-credit-card"></i> Payment
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- /sidebar -->
</template>
<script>
export default {
  computed: {
    currentPath() {
      return this.$route.name;
    },
    currentUser() {
      return this.$store.state.auth.user?.user;
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push("/");
      });
    },
  },
};
</script>
