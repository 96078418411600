import { createStore } from "vuex";
import { auth } from "./auth.module";
import { course } from "./course.module";
import { blog } from "./blog.module";
import { product } from "./product.module";
import { aiagent } from "./aiagent.module";

const store = createStore({
  modules: {
    auth,
    course,
    blog,
    product,
    aiagent
  },
});

export default store;