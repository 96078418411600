<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        
        <layoutslogin></layoutslogin>
		<blogmasonry></blogmasonry>
            <!-- Blog Grid -->
			<section class="course-content">
				<div class="container">
					<div class="row masonry-blog-blk">
						<div class="col-lg-4 col-md-6">
						
							<!-- Blog Post -->
							<div class="blog grid-blog">
								<div class="blog-image">
									<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-08.jpg" alt="Post Image"></router-link>
								</div>
								<div class="blog-grid-box masonry-box">
									<div class="blog-info clearfix">
										<div class="post-left">
											<ul>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">Jun 14, 2022</li>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Web Design</li>
											</ul>
										</div>
									</div>
									<h3 class="blog-title"><router-link to="blog-details">Learn Webs Applications Development from Experts</router-link></h3>
									<div class="blog-content blog-read">
										<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
										<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
									</div>
								</div>
							</div>
							<!-- /Blog Post -->
							
							<!-- Blog Post -->
							<div class="blog grid-blog">
								<div class="blog-image">
									<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-11.jpg" alt="Post Image"></router-link>
								</div>
								<div class="blog-grid-box masonry-box">
									<div class="blog-info clearfix">
										<div class="post-left">
											<ul>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">Sep 18, 2022</li>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Courses</li>
											</ul>
										</div>
									</div>
									<h3 class="blog-title"><router-link to="blog-details">Programming Content Guideline For Beginners</router-link></h3>
									<div class="blog-content blog-read">
										<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
										<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
									</div>
								</div>
							</div>
							<!-- /Blog Post -->
							
						</div>
						<div class="col-lg-4 col-md-6">
						
							<!-- Blog Post -->
							<div class="blog grid-blog">
								<div class="blog-image">
									<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-09.jpg" alt="Post Image"></router-link>
								</div>
								<div class="blog-grid-box masonry-box">
									<div class="blog-info clearfix">
										<div class="post-left">
											<ul>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">May 24, 2022</li>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Courses</li>
											</ul>
										</div>
									</div>
									<h3 class="blog-title"><router-link to="blog-details">Expand Your Career Opportunities With Python</router-link></h3>
									<div class="blog-content blog-read">
										<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
										<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
									</div>
								</div>
							</div>
							<!-- /Blog Post -->
							
							<!-- Blog Post -->
							<div class="blog grid-blog">
								<div class="blog-image">
									<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-09.jpg" alt="Post Image"></router-link>
								</div>
								<div class="blog-grid-box masonry-box">
									<div class="blog-info clearfix">
										<div class="post-left">
											<ul>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">May 24, 2022</li>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Courses</li>
											</ul>
										</div>
									</div>
									<h3 class="blog-title"><router-link to="blog-details">Expand Your Career Opportunities With Python</router-link></h3>
									<div class="blog-content blog-read">
										<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
										<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
									</div>
								</div>
							</div>
							<!-- /Blog Post -->
							
						</div>
						<div class="col-lg-4 col-md-6">
						
							<!-- Blog Post -->
							<div class="blog grid-blog">
								<div class="blog-image">
									<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-10.jpg" alt="Post Image"></router-link>
								</div>
								<div class="blog-grid-box masonry-box">
									<div class="blog-info clearfix">
										<div class="post-left">
											<ul>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">Jun 14, 2022</li>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Web Design</li>
											</ul>
										</div>
									</div>
									<h3 class="blog-title"><router-link to="blog-details">Complete PHP Programming Career Guideline</router-link></h3>
									<div class="blog-content blog-read">
										<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
										<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
									</div>
								</div>
							</div>
							<!-- /Blog Post -->
							
							<!-- Blog Post -->
							<div class="blog grid-blog">
								<div class="blog-image">
									<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-13.jpg" alt="Post Image"></router-link>
								</div>
								<div class="blog-grid-box masonry-box">
									<div class="blog-info clearfix">
										<div class="post-left">
											<ul>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">Feb 14, 2022</li>
												<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Courses</li>
											</ul>
										</div>
									</div>
									<h3 class="blog-title"><router-link to="blog-details">Learn Mobile Applications Development from Experts</router-link></h3>
									<div class="blog-content blog-read">
										<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
										<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
									</div>
								</div>
							</div>
							<!-- /Blog Post -->
							
						</div>
					</div>

					<!-- Load button -->
					<div class="load-more text-center">
						<a href="javascript:;" class="btn btn-primary">Load More</a>
					</div>
					<!-- /Load button -->
				</div>
			</section>
			<!-- /Blog Grid -->
        
        <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
</template>