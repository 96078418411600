<template>
  <!-- Feature Course -->
  <section class="section new-course">
    <div class="container main">
      <div class="section-header aos" data-aos="fade-up">
        <div class="section-sub-head">
          <h2>유료 강의 VOD</h2>
        </div>
        <!-- <div class="all-btn all-category d-flex align-items-center">
                <router-link to="course-list" class="btn btn-primary">All Courses</router-link>
            </div> -->
      </div>
      <!-- <div class="section-text aos" data-aos="fade-up">
            <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget aenean accumsan bibendum gravida maecenas augue elementum et neque. Suspendisse imperdiet.</p>
        </div> -->
      <div class="course-feature">
        <div class="row">
          <div
            v-for="course in courses"
            :key="course.id"
            class="mobile-box col-lg-4 col-md-6 d-flex" 
          >
            <div
              class="course-box d-flex aos"
              :class="{disabled : course.status==1}"
              data-aos="fade-up"
            >
              <div class="product">
                <div class="product-img">
                  <router-link :to="`${getCourseDetailPath(course.id)}?id=${course.id}`">
                    <img
                      v-if="course.image"
                      class="img-fluid"
                      :src="`${serverURL}${course.image.file}`"
                    />
                  </router-link>
                  <router-link 
                      :to="`${getCourseDetailPath(course.id)}?id=${course.id}`"
                      class="price text-center active" v-if="course.status==2">
                      <h3>사전 신청</h3>
                  </router-link>
                  <div class="price text-center active" v-else>
                      <h3>{{course.discount_rate}}% 할인가 : {{$filters.comma(discounted_price(course))}}원</h3>
                  </div>
                </div>
                <div class="product-content">
                  <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                          <div class="course-name">
                              <h4><router-link :to="`${getCourseDetailPath(course.id)}?id=${course.id}`">{{course.title}}</router-link></h4>
                              <p>{{course.intro}}</p>
                          </div>
                      </div>
                  </div>
                  <div class="all-btn all-category d-flex align-items-center">
                    <!-- <a v-if="course.status==1" class="btn btn-primary"
                      >{{ course.month }}월 오픈</a
                    > -->
                    <!-- <router-link v-else
                      :to="`course-details?id=${course.id}`" class="btn btn-primary active"
                      >더 알아보기</router-link
                    > -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Feature Course -->

  <!-- Master Skill -->
  <section class="section master-skill" v-if="windowWidth > 767">
    <div class="container main">
      <div class="row">
        <div class="col-lg-12 col-md-12 d-flex align-items-end">
          <div class="aos" data-aos="fade-up" style="width:100%;">
            <img
              src="../../../assets/img/course/lm_vod.jpeg"
              alt=""
              width="100%"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section master-skill" v-else>
    <div class="container main">
      <div class="row">
        <div class="col-lg-12 col-md-12 d-flex align-items-end">
          <div class="aos" data-aos="fade-up" style="width:100%;">
            <img
              src="../../../assets/img/course/m_lm_vod.jpeg"
              alt=""
              width="100%"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Master Skill -->

  <!-- Master Skill -->
  <!-- <section class="section master-skill">
    <div class="container main">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head">
              <h2>언어모델 학습 로드맵</h2>
            </div>
          </div>
          <div class="section-text aos" data-aos="fade-up">
            <p>
              언어 모델링의 기초부터 전이학습, 최적화 및 효율화 전략, 그리고 실제 언어모델 기반 챗봇 프로젝트 구현까지, 언어모델 전문가가 되기 위한 종합적인 언어모델 학습 로드맵을 제공합니다.
            </p>
          </div>
        </div>
        <div class="col-lg-10 col-md-12">
          <div class="career-group aos" data-aos="fade-up">
            <div class="row">
              <div class="col-lg-12 col-md-12 d-flex">
                <div class="d-flex">
                  <div class="d-flex align-items-center">
                    <div class="blur-box">
                      <div>
                        <img
                          src="../../../assets/img/roadmap.png"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- Master Skill -->
  <!-- <section class="section master-skill">
    <div class="container main">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head d-block d-sm-none">
              <h2>언어모델 전문가로<br /> 성장하세요.</h2>
            </div>
            <div class="section-sub-head d-none d-sm-block">
              <h2>언어모델 전문가로 성장하세요.</h2>
            </div>
          </div>
          <div class="section-text aos" data-aos="fade-up">
            <p>
              이 프로그램은 이론과 실제의 균형을 맞추어, 참가자들이 새로운
              지식을 실질적인 프로젝트에 적용할 수 있도록 돕습니다. 이를 통해
              참가자들은 실무에서 직면하는 도전과제를 해결하는 능력을 키울 수
              있습니다.
            </p>
          </div>
          <div class="career-group aos" data-aos="fade-up">
            <div class="row">
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-1.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>언어모델 설계 전문가</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-2.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>도메인 파인튜닝 전문가</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-3.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>경량화 및 최적화 전문가</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="../../../assets/img/icon/icon-4.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>언어모델 운영 전문가</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 d-flex align-items-end">
          <div class="career-img aos" data-aos="fade-up">
            <img
              src="../../../assets/img/join_02.png"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- /Master Skill -->
</template>
<script>
import AOS from "aos";
import { ref, onMounted, onUnmounted, watchEffect } from 'vue';

export default {
  components: {},
  data() {
    return {
      content: "",
      youtube_courses: [
        'vziygFrRlZ4',
        'jvYpv9VJBOA',
        'T1XadeiKl1M',
        'dLo4QkEq-Hg',
        'u2tQYgrLouo',
        // '8e8x5WYY8W4',
        // 'yl8rDLTWsg8',
        'ha9yYq7ulWQ',
        'TNFYUfM3IQA',
        'HewtI35-lp8',
        // 'bqZrlFoXVfA',
        'zN30JodATrU'
      ]
    };
  },
  setup() {
    const windowWidth = ref(window.innerWidth);
    // resize 이벤트 핸들러
    const updateWidth = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      windowWidth
    };
  },
  computed: {
    courses() {
      return this.$store.state.course.courses;
    },
    products() {
      return this.$store.state.product.products;
    },
  },
  methods: {
    getCourseDetailPath(course_id) {
      // if(course_id == 17) { // 언어모델 기초
      //   return 'course-details'
      // } else if(course_id == 18) { // 사전학습
      //   return 'course-details1'
      // } else if(course_id == 19) { // 파인튜닝
      //   return 'course-details2'
      // }
       return 'course-details'
    },
    onReady() {
    },
    discounted_price(course) {
      let discount_price = 0
      discount_price = course.price * (1 - course.discount_rate/100) 
      discount_price = Math.round(discount_price / 1000) * 1000;
      return discount_price
    },
  },
  mounted() {
    this.$store.dispatch("course/course_list");
    this.$store.dispatch("product/product_list");

    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
  },
};
</script>
