<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper blog-list">
        <layoutsindex></layoutsindex>
        <!-- Course -->
        <section class="course-content">
            <div class="container aos"  data-aos="fade-up">
                <div class="section-header">
                    <div class="section-sub-head">
                    <h2>기술 블로그</h2>
                    </div>
                    <!-- <div class="all-btn all-category d-flex align-items-center">
                            <router-link to="course-list" class="btn btn-primary">All Courses</router-link>
                        </div> -->
                </div>
                <hr />
                
                <div class="row aos"  data-aos="fade-up">
                    <div class="col-lg-9 col-md-12">
                        <!-- Blog Post -->
                        <div class="blog" 
                            v-for="blog in blogs" :key="blog.id">
                            <div class="blog-image">
                                <router-link :to="`blog-details?id=${blog.id}`">
                                    <img 
                                        class="img-fluid" 
                                        v-if="blog.image"
                                        :src="`${serverURL}${blog.image.file}`">
                                </router-link>
                            </div>
                            <h3 class="blog-title"><router-link :to="`blog-details?id=${blog.id}`">{{blog.title}}</router-link></h3>
                            <div class="blog-info clearfix">
                                <div class="post-left">
                                    <ul>
                                        <li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">{{created_at(blog)}}</li>
                                        <li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">{{blog.category}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- /Blog Post -->
                        <!-- <pagination></pagination> -->
                    </div>
                    
                    <blogsidebar></blogsidebar>
                </div>
            </div>
        </section>
        <!-- /Course -->

        <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
</template>

<script>
import dayjs from "dayjs";
import AOS from "aos";

export default {
  components: {},
  data() {
    return {
      content: "",
    };
  },
  computed: {
    blogs() {
      return this.$store.state.blog.blogs;
    },
    
  },
  methods: {
    created_at(item) {
        return dayjs(item.created_at).format('M월 D일 A h시 m분')
    }
  },
  mounted() {
    this.$store.dispatch("blog/blog_list");

    if ($(".main-wrapper .aos").length > 0) {
      AOS.init({
        duration: 1200,
        once: true,
      });
    }
  },
};
</script>
