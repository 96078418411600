<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts2></layouts2>
    <checkoutbreadcrumb></checkoutbreadcrumb>
    <!-- Cart -->
    <section class="course-content checkout-widget">
      <div class="container">
        <div class="row">
          <div class="col-lg-7" v-if="currentUser">
            <!-- Billing Address -->
            <div class="student-widget">
              <div class="student-widget-group add-course-info">
                <div class="cart-head">
                  <h4>결제자 정보</h4>
                </div>
                <div class="checkout-form">
                  <form action="#">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">이름</label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Enter your first Name"
                            v-model="userForm.name"
                          />
                          
                        </div>
                        
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label">이메일</label>
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Enter your Email"
                            v-model="userForm.email"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="form-control-label"
                            >전화번호</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Phone Number"
                            v-model="userForm.phone"
                          />
                        </div>
                      </div>
                      <!-- <div class="col-md-12 col-lg-10">
                        <div class="form-group ship-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            name="remember"
                            v-model="checkPolicy"
                          />
                          <router-link to="term-condition">이용약관 동의</router-link>
                        </div>
                      </div> -->
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <!-- /Billing Address -->

            <!-- /Payment Method -->
            <div class="student-widget pay-method" v-if="course">
              <div class="student-widget-group add-course-info">
                <div class="cart-head">
                  <h4>결제</h4>
                </div>
                <div class="checkout-form">
                  <div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="wallet-method">
                          <label class="radio-inline custom_radio me-4">
                            <input type="radio" name="optradio" checked="" />
                            <span class="checkmark"></span> 신용카드 <small>(현재는 신용카드 결제만 가능합니다.)</small>
                          </label>
                        </div>
                      </div>
                      <div class="payment-btn">
                        
                        <button class="btn btn-primary" type="submit" @click="requstPayment(course)">
                          결제 요청
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Payment Method -->
          </div>
          <div class="col-lg-5 theiaStickySidebar" v-if="course">
            <div class="stickysidebar">
              <div class="student-widget select-plan-group">
                <div class="student-widget-group">
                  <div class="plan-header">
                    <h4>강의 정보</h4>
                  </div>
                  <div class="basic-plan">
                    <h2>{{formatCurrency(discounted_price)}} <span>원</span></h2>
                  </div>
                  <div class="benifits-feature">
                    <h3>강의 정보</h3>
                    <ul>
                      <li>
                        <i class="fas fa-circle"></i> 강의명칭: {{course.title}}
                      </li>
                      <li>
                        <i class="fas fa-circle"></i> 이용기간: 6개월
                      </li>
                    </ul>
                  </div>
                  <div class="benifits-feature">
                    <h6 class="cou-title">
                      <a
                        class="collapsed"
                        data-bs-toggle="collapse"
                        href="#collapseOne"
                        aria-expanded="false"
                        >환불 정책</a
                      >
                    </h6>
                    
                    <ul id="collapseOne" class="refund-text collapse" style="margin-top: 20px;">
                      <li>
                        <i class="fas fa-circle"></i> 수강시작 전 100% 환불 가능
                      </li>
                      <li>
                        <i class="fas fa-circle"></i> 수강 10% 미만 : 10% 위약금 및 이용비율로 공제 후 환불
                      </li>
                      <li>
                        <i class="fas fa-circle"></i> 수강 50% 미만 : 50% 위약금 및 이용비율로 공제 후 환불
                      </li>
                      <li>
                        <i class="fas fa-circle"></i> 수강 50% 이상 : 환불 뷸가
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- /Cart -->

    <layouts1></layouts1>
    <widget-container-modal />
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import dayjs from "dayjs";
import Vue from "vue";
import {openModal, closeModal, container} from "jenesius-vue-modal";
import PaymentSuccessModal from '../instructor/paymentsuccessmodal.vue'
import PaymentFailModal from '../instructor/paymentfailmodal.vue'


export default {
  components: {WidgetContainerModal: container},
  data() {
    return {
      checkPolicy: false,
      userForm: {
        name: '',
        phone: '',
        email: ''
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user?.user;
    },
    course() {
      return this.$store.state.course.course;
    },
    discounted_price() {
      let discount_price = 0
      discount_price = this.$store.state.course?.course.price * (1 - this.$store.state.course?.course.discount_rate/100) 
      discount_price = Math.round(discount_price / 1000) * 1000;
      return discount_price
    }
  },
  mounted() {
    this.userForm = this.currentUser

    closeModal()
    this.$store.dispatch("course/course_item", this.$route.query.id).then((course)=> {
      
      if(this.$route.query.imp_success == 'true') {
        this.joinCourseRequest(course.id)
        openModal(PaymentSuccessModal)
      }
    });
  },
  beforeRouteLeave() {
    closeModal()
  },
  methods: {
    requstPayment(course) {

      if(!this.userForm.name || !this.userForm.email || !this.userForm.phone) {
        alert('결제자 정보를 입력해주세요.')
        return
      }

      const { IMP } = window;
      IMP.init("imp05308082");

      IMP.request_pay(
        {
          pg: "kcp",
          pay_method: "card",
          merchant_uid: "merchant_" + dayjs().valueOf(),
          name: this.course.title,
          amount: this.discounted_price,
          buyer_email: this.userForm.email,
          buyer_name: this.userForm.name,
          buyer_tel: this.userForm.phone,
          // buyer_addr: this.user.address,
          m_redirect_url: `https://quantumai.kr/checkout?id=${this.course.id}`,
        },
        (rsp) => {
          if (rsp.success) {
            this.joinCourseRequest(this.course.id)
          } else {
            if(rsp.error_msg.includes('[결제포기]')) {
              console.log(rsp.error_msg);
            } else {
              openModal(PaymentFailModal)
            }
          }
        },
        (error) => {
          openModal(paymentfailmodal)
        }
      );
    },
    formatCurrency(value) {
      return value.toLocaleString('ko-KR');
    },
    joinCourseRequest(course_id) {
      this.$store.dispatch("course/join_course", course_id).then(()=>{
        openModal(PaymentSuccessModal)
      })
    }
  },
};
</script>
