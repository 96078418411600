<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper course-list">
    <layoutsindex></layoutsindex>
    <!-- <homebanner></homebanner> -->
    <featuremodel></featuremodel>
    <!-- <trendingcourse></trendingcourse> -->
    <!-- <shareknowledge></shareknowledge> -->
    <!-- <latestblog></latestblog> -->
    <layouts1></layouts1>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import Vue from "vue";
export default {
  components: {},
  data() {
    return {};
  },
  mounted() {
    
  },
  name: "index",
};
</script>
