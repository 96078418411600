import ProductService from "../services/product.service";

const initialState = {
  myproducts: null,
  products: null,
  product: null,
  sublesson: null,
  history: null,
}
  
export const product = {
  namespaced: true,
  state: initialState,
  actions: {
    product_list({ commit }, org) {
      return ProductService.getPublicProductList(org).then(
        (response) => {
          commit("productListSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("productListFailure");
          return Promise.reject(error);
        }
      );
    },
    product_item({ commit }, id) {
      return ProductService.getPublicProduct(id).then(
        (response) => {
          commit("productSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("productFailure");
          return Promise.reject(error);
        }
      );
    },
    myproduct_list({ commit }, org) {
      return ProductService.getMyProductList(org).then(
        (response) => {
          commit("myproductListSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("myproductListSuccess");
          return Promise.reject(error);
        }
      );
    },
    join_product({commit}, id) {
      return ProductService.joinProductRequest(id).then(
        (response) => {
          commit("productSuccess", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("productFailure");
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    productListSuccess(state, products) {
      state.products = products;
    },
    productListFailure(state) {
      state.products = null;
    },
    myproductListSuccess(state, products) {
      state.myproducts = products;
    },
    myproductListFailure(state) {
      state.myproducts = null;
    },
    productSuccess(state, product) {
      state.product = product
    },
  },
};
