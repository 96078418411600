<template>
  <!-- Course Header -->
  <div class="course-student-header" v-if="currentUser">
    <div class="container">
      <div class="student-group">
        <div class="course-group">
          <div class="course-group-img d-flex">
            <!-- <router-link to="student-profile"><img src="../assets/img/user/user11.jpg" alt="" class="img-fluid"></router-link> -->
            <div class="d-flex align-items-center">
              <div class="course-name">
                <h4>
                  <a v-if="currentUser.name" href="javascript:;">{{currentUser.name}}</a>
                  <a v-else href="javascript:;">{{currentUser.username}}</a>
                  <span>Beginner</span>
                </h4>
              </div>
            </div>
          </div>
          <!-- <div class="course-share ">
            <router-link to="setting-edit-profile" class="btn btn-primary">Account Settings</router-link>
          </div> -->
        </div>
      </div>
      <div class="my-student-list">
        <ul>
          <li :class="currentPath == 'course-student' ? 'active' : 'notactive'">
            <router-link to="course-student">진행중인 강의</router-link>
          </li>
          <li 
              :class="currentPath == 'product-student' ? 'active' : 'notactive'">
            <router-link to="product-student">내 모델목록</router-link>
          </li>
          <!-- <li :class="currentPath == 'course-wishlist' ? 'active' : 'notactive'"><router-link to="course-wishlist">Wishlists</router-link></li> -->
          <!-- <li :class="currentPath == 'course-message' ? 'active' : 'notactive'"><router-link to="course-message">소통채널</router-link></li> -->
          <!-- <li :class="currentPath == 'purchase-history' ? 'active' : 'notactive'" class="mb-0"><router-link to="purchase-history">강의 히스토리</router-link></li> -->
        </ul>
      </div>
    </div>
  </div>
  <!-- /Course Header -->
</template>
<script>
export default {
  computed: {
    currentPath() {
      return this.$route.name;
    },
    currentUser() {
      return this.$store.state.auth.user?.user;
    },
  },
};
</script>
