import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.BASE_URL || 'http://localhost:8000/api'


class AiagentService {
  chatting(payload) {
    return axios.post(API_URL + `/aiagent/`, {
      content: payload.content
    }, {
      headers: authHeader()
    });
  };

  message_list(agent_id) {
    return axios.get(API_URL + `/aiagent/messages/${agent_id}/`, {
      headers: authHeader()
    });
  }
}
export default new AiagentService();