<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        
        <layoutslogin></layoutslogin>
       
        <bloggrid></bloggrid>

            <!-- Blog Grid -->
			<section class="course-content">
				<div class="container">
					<div class="row">
						<div class="col-lg-9 col-md-12">
							<div class="row">
								<div class="col-md-6 col-sm-12">
								
									<!-- Blog Post -->
									<div class="blog grid-blog">
										<div class="blog-image">
											<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-08.jpg" alt="Post Image"></router-link>
										</div>
										<div class="blog-grid-box">
											<div class="blog-info clearfix">
												<div class="post-left">
													<ul>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">Jun 14, 2022</li>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Web Design</li>
													</ul>
												</div>
											</div>
											<h3 class="blog-title"><router-link to="blog-details">Learn Webs Applications Development from Experts</router-link></h3>
											<div class="blog-content blog-read">
												<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
												<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
											</div>
										</div>
									</div>
									<!-- /Blog Post -->
									
								</div>
								<div class="col-md-6 col-sm-12">
								
									<!-- Blog Post -->
									<div class="blog grid-blog">
										<div class="blog-image">
											<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-09.jpg" alt="Post Image"></router-link>
										</div>
										<div class="blog-grid-box">
											<div class="blog-info clearfix">
												<div class="post-left">
													<ul>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">May 24, 2022</li>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Courses</li>
													</ul>
												</div>
											</div>
											<h3 class="blog-title"><router-link to="blog-details">Expand Your Career Opportunities With Python</router-link></h3>
											<div class="blog-content blog-read">
												<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
												<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
											</div>
										</div>
									</div>
									<!-- /Blog Post -->
									
								</div>
								<div class="col-md-6 col-sm-12">
								
									<!-- Blog Post -->
									<div class="blog grid-blog">
										<div class="blog-image">
											<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-10.jpg" alt="Post Image"></router-link>
										</div>
										<div class="blog-grid-box">
											<div class="blog-info clearfix">
												<div class="post-left">
													<ul>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">Jun 14, 2022</li>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Web Design</li>
													</ul>
												</div>
											</div>
											<h3 class="blog-title"><router-link to="blog-details">Complete PHP Programming Career Guideline</router-link></h3>
											<div class="blog-content blog-read">
												<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
												<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
											</div>
										</div>
									</div>
									<!-- /Blog Post -->
									
								</div>
								<div class="col-md-6 col-sm-12">
								
									<!-- Blog Post -->
									<div class="blog grid-blog">
										<div class="blog-image">
											<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-11.jpg" alt="Post Image"></router-link>
										</div>
										<div class="blog-grid-box">
											<div class="blog-info clearfix">
												<div class="post-left">
													<ul>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">Sep 18, 2022</li>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Courses</li>
													</ul>
												</div>
											</div>
											<h3 class="blog-title"><router-link to="blog-details">Programming Content Guideline For Beginners</router-link></h3>
											<div class="blog-content blog-read">
												<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
												<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
											</div>
										</div>
									</div>
									<!-- /Blog Post -->
									
								</div>
								<div class="col-md-6 col-sm-12">
								
									<!-- Blog Post -->
									<div class="blog grid-blog">
										<div class="blog-image">
											<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-12.jpg" alt="Post Image"></router-link>
										</div>
										<div class="blog-grid-box">
											<div class="blog-info clearfix">
												<div class="post-left">
													<ul>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">Jun 26, 2022</li>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Web Design</li>
													</ul>
												</div>
											</div>
											<h3 class="blog-title"><router-link to="blog-details">The Complete JavaScript Course for Beginners</router-link></h3>
											<div class="blog-content blog-read">
												<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
												<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
											</div>
										</div>
									</div>
									<!-- /Blog Post -->
									
								</div>
								<div class="col-md-6 col-sm-12">
								
									<!-- Blog Post -->
									<div class="blog grid-blog">
										<div class="blog-image">
											<router-link to="blog-details"><img class="img-fluid" src="../../../assets/img/blog/blog-13.jpg" alt="Post Image"></router-link>
										</div>
										<div class="blog-grid-box">
											<div class="blog-info clearfix">
												<div class="post-left">
													<ul>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-22.svg" alt="">Feb 14, 2022</li>
														<li><img class="img-fluid" src="../../../assets/img/icon/icon-23.svg" alt="">Programming, Courses</li>
													</ul>
												</div>
											</div>
											<h3 class="blog-title"><router-link to="blog-details">Learn Mobile Applications Development from Experts</router-link></h3>
											<div class="blog-content blog-read">
												<p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]</p>
												<router-link to="blog-details" class="read-more btn btn-primary">Read More</router-link>
											</div>
										</div>
									</div>
									<!-- /Blog Post -->
									
								</div>
							</div>

							<pagination></pagination>
							
						</div>
						
						<blogsidebar></blogsidebar>
						
					</div>
				</div>
			</section>
			<!-- /Blog Grid -->
        
      <layouts1></layouts1>
       
    </div>
    <!-- /Main Wrapper -->
    </template>